@import '../twitterAds/variables';
@import '../twitterAds/buttons';

.dashboard-header {
  padding: 12px 30px;
  background-color: @header-background-color;
  border-bottom: 1px solid @header-border-color;
  -webkit-box-shadow: 0px 1px 1px @header-border-color;
  -moz-box-shadow: 0px 1px 1px @header-border-color;
  box-shadow: 0px 1px 1px @header-border-color;
  h1 {
    color: @darkerFontColor;
    font-weight: bold;
    font-size: 28px;
  }

  .campaign-name {
    font-weight: 700;
    max-width: 75%;
    display:inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h1 {
    position: relative;
    margin: 0;

    li:first-child a {
      .rounded-btn-primary;
      &:hover {
        color: #fff;
        background-color: @submitButtonHoverColor;
        border-color: @submitButtonHoverColor;
        text-decoration: none;
      }
    }

  }
}

.txt-label {
  color: @fontColor;
  font-size: 12px;
}

.group-summary {
  margin-bottom: 0;
  margin-top: 0;

  .ondemand-box {
    background: none;
    padding-left: 0;

    &:before {
      content: none;
    }
  }

  .status-icon {
    padding-top: 3px;
  }

}

.menu-action {
  position: absolute;
  right: 0;
  bottom: 8px;

  li {
    a {
      .rounded-btn-default;
      border: 1px solid @submit-background-color;
      padding: 5px 12px;
      font-weight: bold;
      font-size: 13px;
      background-color: white;
      letter-spacing: 0;

      &:hover {
        background-color: @defaultButtonHoverColor;
        border-color: @submitButtonHoverColor;
        text-decoration: none;
      }

    }
  }
}

.rules {
  .menu-action {
    right: 13px;
    top: 34px;
    bottom: initial;
  }
}

/** HEADER BG PANEL */
.header-tab-content {
  padding: 12px 0;
  background: #f4f7f9;
  border-bottom: 1px solid #e8ecef;
  overflow: visible;
  margin-bottom: 20px;
}

.container .header-tab-content.row {
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 9px;
  padding-right: 9px;
}

.header-tab-content .header-filter .filter-container {
  padding: 0;
}

.header-tab-content .menu-create .btn {
  margin-top: 5px;
}

.no-wrap-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.report-form {
  .btn-default.btn-sm {
    .metric-plugin;
  }

  .annotatedList.annotatedListAvailable.inset {
    margin-top: 33px;
    min-height: 215px!important;
  }

  .selected-date-range-btn {
    height: 34px;
  }

  .onwardsTwitter {
    color: #666;
  }

  .react-select__dropdown-indicator {
    padding: 6px!important;
  }

  .react-select-wrapper {
    width: 220px;
    display: inline-block;

    .react-select {
      margin: 0;
    }
  }
}

.scrollbar-plugin {
  &::-webkit-scrollbar {
    width: 14px;
    height: 18px;
  }
  &::-webkit-scrollbar-thumb {
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

.column-picker-scrollbar-plugin {
  &::-webkit-scrollbar {
    width: 10px;
    height: 18px;
    background-color: #e9ecef;
  }
  &::-webkit-scrollbar-thumb {
    height: 50px;
    background-color: #5a6c7b;
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
.channel-box {
  padding: 20px 15px 55px 15px;
  margin-bottom: 10px;
  background-color: @header-background-color;
  h4{
    display: none;
  }
  .menu-create{
    position: absolute;
    bottom: 20px;
  }
}
.fold-paper:after{
  display: none;
}

.available-channels {
  h3 {
    font-size: 14px;
  }
}

.text-metric {
  padding: 25px 0;
  text-align: left;
  min-height: 5em;
}

.text-metric-value {
  font-size: 14px;
  color: #586c7a;
}

.text-metric-label {
  font-size: 13px;
  text-transform: uppercase;
  color: #858585;
}

@media (min-width: 912px) {
  .text-metric-value {
    font-size: 22px;
  }

  .text-metric-label {
    font-size: 13px;
  }
}

.text-metric-scroll {
  overflow-y: hidden;
  overflow-x: auto;
  .text-metric-label,
  .text-metric-value {
    white-space: nowrap;
  }
  height: auto;
}

.no-data {
  text-align: center;
  margin: 15px 0;
  color: #707070;
}

.Select-control {
  border: 1px solid @input-border-color;
  border-radius: @inputBorderRadius !important;
}

.Select--multi {
  .Select-value {
    background-color: @viking;
    border-radius: @inputBorderRadius;
    border: none;
    margin: 5px 0 5px 5px;
  }

  .Select-value,
  .Select-value-icon,
  a.Select-value-label {
    color: #fff;

  }

  .Select-value-label,
  a.Select-value-label {
    padding: 3px 5px 3px;
    border-radius: 3px;
    border: none;
  }

  .Select-value-icon {
    border-right: none;
    padding: 1px 5px;
    font-size: 17px;
    line-height: 18px;
  }

  .Select-value-icon:focus,
  .Select-value-icon:hover {
    background-color: @viking;
  }

  .Select-value.active a.Select-value-label,
  &.is-disabled .Select-value.active a.Select-value-label,
  .Select-value.active .Select-value-label,
  &.is-disabled .Select-value.active .Select-value-label,
  &.is-disabled .Select-value {
    color: #fff;
    background-color: lighten(@viking, 10%);
    border: none;
  }

  // reduce margin once there is value
  &.has-value .Select-input {
    margin-left: .5em;
  }
}

.detailed-filters-row {
  .Select--multi {
    .Select-value {
      background-color: #69b9f1;
    }

    .Select-value.active a.Select-value-label,
    &.is-disabled .Select-value.active a.Select-value-label,
    .Select-value.active .Select-value-label,
    &.is-disabled .Select-value.active .Select-value-label,
    &.is-disabled .Select-value {
      color: #fff;
      background-color: lighten(#69b9f1, 10%);
      border: none;
    }

    .Select-value-icon:focus,
    .Select-value-icon:hover {
      background-color: #69b9f1;
    }
  }

}

.Select {
  .Select-control {
    height: 34px;

    .Select-multi-value-wrapper {
      .Select-placeholder {
        line-height: 34px;
      }

      .Select-input {
        & > input {
          line-height: 24px;
        }
      }
    }

    .Select-clear-zone {
      .Select-clear {
        padding: 6px 10px;
      }

    }
  }
}

.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  line-height: 32px;
}

.Select.sm {
  .Select-control .Select-placeholder,
  .Select-control .Select-value,
  .Select-control .Select-value .Select-value-label {
    line-height: 27px;
  }
}

.multi-scroll-select {
  .Select--multi .Select-multi-value-wrapper {
    min-height: 34px;
    max-height: 158px;
    overflow-y: auto;
    display: block;
    padding-right: 40px;
  }

  .Select-clear-zone {
    display: block;
    position: initial;
  }

  .Select-clear {
    right: 13px;
  }

  .Select-menu-outer {
    top: initial;
  }
}

.filter-type {
  .multi-scroll-select {
    .Select--multi .Select-multi-value-wrapper {
      min-height: 32px;
    }

    .Select-clear {
      right: 0;
    }
  }

  .Select .Select-control .Select-clear-zone .Select-clear {
    padding: 7px 10px;
  }

  .Select--multi {
    .Select-value-icon {
      padding: 2px 5px;
    }

    .Select-value {
      line-height: 1.6;
    }
  }
}

.audienceCaret {
  font-size: 28px;
  color: #d3d6da;
  margin-left: -29px;
  position: relative;
  top: 4px;
}

.audienceConnection {
  left: -20px;
  border-left: 1px solid #d3d6da;
  height: 80px;
  float: left;
  position: relative;
  top: 28px;
}

.audienceList {
  .singleAudience {
    .audiencePin {
      float: left;
      width: 15px;
      height: 20px;
      position: relative;
      left: -5px;
      top: 8px;

      border-top: 1px solid #d3d6da;
      border-bottom: 1px solid #d3d6da;
    }
  }

  div:first-child {
    .audiencePin {
      border-top: 0;
    }
  }

  div:last-child {
    .audiencePin {
      border-bottom: 0;
    }
  }
}

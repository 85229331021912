@import "facelift/mixins.less";

.font-face('Roboto';roboto-v18-latin-300;300;normal);
.font-face('Roboto';roboto-v18-latin-regular;400;normal);
.font-face('Roboto';roboto-v18-latin-italic;400;italic);
.font-face('Roboto';roboto-v18-latin-700;700;normal);

body {
  font-size: 14px;

  font-family: 'Roboto',sans-serif !important;
  font-display: block;

  h1, h2, h3, h4 {
    font-family: 'Roboto', sans-serif !important;
  }

  h3, h4 {
  }

  label {
    font-size: 14px;
  }

  input {
    font-size: 12px;
  }

  .caption, .metrics-caption {
    font-size: 12px;
  }

  .status-text,
  .status-icon,
  .txt-value {
    font-size: 14px;
  }

  .rules {
    .autopilot {
      .details {
        h6 {
          color: @shuttleGray;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
  .rules .rules-group .single-rule-container .rules-container .condition.full>span:nth-child(1) {
    color: @shuttleGray;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
  }
}

p + p {
  margin-top: 0 !important;
}

@import '../twitterAds/variables';

@keyframes halfElement {
  0% {
    width: 95%;
  }

  100% {
    width: 50%;
  }
}

@keyframes halfElementFromRightToLeft {
  0% {
    width: 95%;
    float: right;
  }

  100% {
    width: 50%;
    float: right;
  }
}

@keyframes hideElementFromRightToLeft {
  0% {
    padding-left: 15px;
    width: 50%;
    overflow: hidden;
    flex-wrap: nowrap;
  }

  100% {
    padding-left: 15px;
    width: 0;
    overflow: hidden;
    flex-wrap: nowrap;

  }
}

@keyframes hideElementFromLeftToRight {
  0% {
    padding-right: 15px;
    width: 50%;
    float: right;
    overflow: hidden;
    flex-wrap: nowrap;
  }

  100% {
    padding-right: 15px;
    width: 0;
    overflow: hidden;
    flex-wrap: nowrap;
    float: right;
  }
}

@keyframes showElement {
  0% {
    width: 0;
  }

  100% {
    width: 50%;
  }
}

@keyframes showElementFromRightToLeft {
  0% {
    width: 0;
    float: right;
  }

  100% {
    width: 50%;
    float: right;
  }
}


@keyframes expandElement {
  0% {
    width: 50%;
  }

  100% {
    width: 100%;
  }
}

@keyframes expandElementFromRightToLeft {
  0% {
    float: right;
    width: 50%;
  }

  100% {
    float: right;
    width: 100%;
  }
}

@animationShow: 0.4s;
@animationHide: 0.3s;

.chart-wrapper {
  background: @highlight-background-color;
  border-radius: 3px;
  padding: 20px;
  min-height: 568px;
}

.ads-overview-chart {
  .right-chart.none-right{
    animation: @animationShow 0s expandElementFromRightToLeft;

    .chart-wrapper div:nth-child(3) {
      .highcharts-container{
        float: right;
      }
    }
  }

  .left-chart.none-left{
    animation: @animationShow 0s expandElement;
  }

  .right-chart.right-none{
    animation: @animationShow 0s halfElementFromRightToLeft;
  }

  .left-chart.left-none{
    animation: @animationShow 0s halfElement;
  }

  .right-chart.left-none{
    animation: @animationShow 0s showElementFromRightToLeft;
  }

  .left-chart.right-none{
    animation: @animationShow 0s showElement;
  }

  .left-chart.none-right {
    animation: @animationHide 0s hideElementFromRightToLeft;
  }

  .right-chart.none-left {
    animation: @animationHide 0s hideElementFromLeftToRight;
    .chart-wrapper div{
      padding: 0;
    }
  }

  .left-chart.none-right, .right-chart.none-left{
    width: 0;
    padding: 0;

    .chart-wrapper {
      padding: 0;

      div {
        width: 0;
        overflow: hidden;
        flex-wrap: nowrap;
      }
    }
  }
}

.ads-overview-chart .chart-warning {
  padding: 0 15px;
}

.chartContainer .chartInfo {
  font-family: Roboto, sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: #707070;
  text-align: center;
}

.custom-charts-dropdown {
  min-width: 128px;
  padding:   8px;
}

.custom-charts-dropdown .Select-value-label {
  font-size: 12px;
}

.custom-charts-dropdown-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 28px; // so fullscreen button is never overflowing
}

.custom-charts-dropdown-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
}

.custom-charts-dropdown-container .Select-menu-outer {
  width: 200px;
}

.group-select-remove-container {
  border-radius: 3px;
  background-color: rgb(230, 230, 230);
  display: flex;
  justify-content: space-between;
  border: 1px solid #d5d5d5;
  padding-left: 1px;
}

.group-select-remove-button {
  width: 10px;
  height: 100%;
  color: rgb(133, 122, 134);
  cursor: pointer;
  font-size: 15px;
  align-self: center;
  margin-right: 17px;
  font-weight: bold;
  padding-left: 8px;
}

.group-select-remove-button:hover {
  color: rgb(156,156,156);
}

.group-select-remove-container .Select-group {
  padding: 0 !important;
}

.group-select-remove-container .Select-control {
  border: none !important;
}


.disabled-overlay {
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgb(133, 122, 134);
  z-index: 1000;
  width: 150px;
  height: 50px;
}

.group-select-disabled {
  opacity: 0.1;
}

.group-select-disabled .Select {
  all: initial !important;
  opacity: 1.0 !important;
}

.group-select-remove-container .disabled-overlay {
  top: 0;
  left: 0;
  width: 100%;
  position: relative;
  background-color: rgb(206,206,206);
  z-index: 1000;
}

.group-select-remove-disabled .Select-control {
  background-color: rgb(238,238,238);
}

.no-rows-text {
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  color: #707070;
}

.custom-charts-dropdown-container span {
  color: #858585 !important;
  font-size: 13px;
}

@media (max-width: 1735px) {
  .custom-charts-dropdown-group .chart-label {
    width: 70px;
  }
}

.Select-menu-outer [role='listbox'] {
  font-weight: 400;
  font-size: 12px;
}

.placeholder() {
  color: #aaa;
  font-size: 12px;
}

.position-overlay {
  margin-top: -18px !important;
}

.position-overlay-switched {
  margin-top: -67px !important;
}

.star {
  display: inline-block;
  vertical-align: top;
  margin-left: 2px;
}

#workspace-manager {
  font-weight: 400;
  line-height: 15px;

  & > span:first-child {
    padding-top: 2px;
    display: inline-block;
    min-width: 190px;
    max-width: 250px;

    & > span:first-child {
      display: inline-block;
      max-width: 242px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .caret {
    margin-left: 3px;
    vertical-align: super;
    position: relative;
    top: 1px;
  }

  &:hover {
    color: #ddd;
  }

  &+ .dropdown-menu.dropdown-menu-right {
    .grey-button {
      position: relative;
      top: -1px;
      background-color: @grayColor;
      border-radius: 6px;
      text-align: center;
      width: 20px;
      margin-right: 10px;

      .button-text {
        color: white;
        font-size: 9px;
        font-weight: normal;
      }
    }

    .item-text {
      max-width: 500px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      vertical-align: middle;
    }

    .disabled {
      .item-text, .button-text {
        color: @input-border-color;
      }
    }
  }
}

.form input.value.long.new-workspace-name {
  width: 100%!important;
}

.new-workspace-label {
  position: absolute;
  color: #858585;
}

.workspace-cross {
  width: 16px;
}

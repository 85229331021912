.ad-group-create {
  padding-top: 0;
  max-width: 1200px;

  .firstUse:not(.noLine) {
    border-bottom: 1px solid #e9ecef;
    padding:20px;
  }

  .floater-content-box{
    padding:10px;
    -webkit-border-radius: @borderRadius;
    -moz-border-radius: @borderRadius;
    border-radius: @borderRadius;
  }

  .floater-estimates.estimates .floater-info.grayed-out{
    background:white;
    border-bottom: none;
  }

  .btn-group.btn-group-sm > button{
    border-radius: 20px;
  }

  .react-panel-content-margin{
    margin :0;

    div.pull-right {
      position: absolute;
      top: 18px;
      right: 0;
    }

    .suggestion-select {
      div.pull-right {
        top: unset;
      }
    }
  }

  .followers-targeting {
    .react-panel-content-margin{
      margin :0;

      div.pull-left {
        float: unset!important;
        .pull-right {
          float:unset!important;
        }
      }

      div.pull-right {
        position: static;
        top: unset;
        right: unset;
      }

      .suggestion-select {
        div.pull-right {
          float: unset!important;
        }

        .ondemand-box {
          .twFollowersSelectionContainer {
            padding: 4px 8px!important;
            &> div {
              padding: 3px!important;
            }
          }

          .top-spacer-10 {
            padding-left: 0;
          }
        }
      }

      .form.top-spacer-10 {
        .ondemand-box {
          div {
            padding-left: 0;
            .Select {
              .Select-menu {
                  padding-left: 10px;
              }
            }
          }

          .Select-placeholder {
            padding-left: 10px;
          }

          .top-spacer-5 {
            padding-left: 0;
          }
        }
        .bulk-select {
          .ondemand-box {
            .top-spacer-5 {
              padding-left: 0px;
            }
          }
        }
      }
    }
  }

  .panel:before {
    border: none;
  }

  .panel {
    .panel-body {

      max-width: 640px !important;
      @media (max-width: 992px) {
        max-width: 100% !important;
      }

      &.followers-targeting {
        .react-panel-content-margin {
          max-width: 640px !important;

          @media (max-width: 992px) {
            max-width: 100% !important;
          }

          .absolute {
            right: -90% !important;
            top: 26px !important;
            position: relative;
          }

          .form.top-spacer-10 {
            margin: 0;


            .top-spacer-5 {
              .bulk-select {

                .pull-left {
                  float: unset!important;
                }
              }
            }

            .value {
              .limit-max {
                .ondemand-box {
                  border: none;
                }
              }

              .bulk-select {
                .ondemand-box {
                  border: none;

                  .value.followersTextarea {
                    height: 100px;
                    border-radius: @borderRadius !important;

                    &.smallHeight {
                      height: 50px;
                    }
                  }

                  .twFollowersSelectionContainer {
                    border-radius: @borderRadius !important;
                    padding: 0;

                    .top-spacer-5.twFollowerBulkSingle {
                      &.twFollowerSelectable, &.twFollowerUnselected {
                        margin-top: 0;
                        padding: 7px 10px;
                        font-weight: 300;
                        border-top: 1px solid transparent;
                        border-bottom: 1px solid @input-border-color;

                        img {
                          border-radius: 50%;
                        }

                        a {
                          font-weight: normal;
                        }

                        &:last-child {
                          border: none;
                          border-bottom: 1px solid transparent;
                          border-top: 1px solid transparent;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .react-panel-content-margin {
        .value {
          .limit-max {
            .ondemand-box {
              border: none;
              &> textarea {
                border-radius: @borderRadius!important;
              }
            }
          }
        }
      }

      .react-panel-content-margin {
        margin: 0;

        .caption {
          text-transform: uppercase;
          line-height: 34px;
          padding: 0;
          width: 100%;
          float: none;

          span:first-child {
            font-weight: bold;
          }

          &.normal span:first-child {
            font-weight: normal;
          }
        }

        .value {
          margin-left: 0;

          .segment-behaviors, .segment-interests {
            &> label {
              width: 100%;
            }
          }

          .annotatedListContainer {
            overflow: visible;

            .annotatedListSpacer {
              margin-top: 50px;
            }
          }

          #total_budget {
            line-height: 32px;
            padding: 0 10px!important;
            border-radius: @inputBorderRadius;
          }

          label[for=unlimited_budget] {
            padding-top: 0;
            line-height: 34px;
          }

          .no-focus-highlight {
            margin: 0;
            .btn-group {
              > button {
                padding: 0 25px;
                line-height: 32px;
                border-width: 1px;
              }

              .btn.btn-default {
                box-shadow: none;
                border-color: @border-color;
                background-color: @highlight-background-color;
                color: @nav-bar-background-color;
              }
              .active.btn.btn-default {
                border-color: @submit-background-color;
                background-color: @submit-background-color;
                color: white;
              }
            }

            .col-xs-1 {
              width: 75px;

              .btn-group {
                #anyGender {
                  border-radius: 0;
                  border-top-left-radius: @borderRadius;
                  border-bottom-left-radius: @borderRadius;
                }
              }
            }

            .col-xs-8 {
              padding: 0;

              .btn-group {
                #man {
                  border-radius: 0;
                }

                #woman {
                  border-radius: 0;
                  border-top-right-radius: @borderRadius;
                  border-bottom-right-radius: @borderRadius;
                }
                #preciseGender {
                  border-radius: 0;
                  border-top-right-radius: @borderRadius;
                  border-bottom-right-radius: @borderRadius;
                }
              }

              .age-container {
                .btn-group {
                  &> button {
                    line-height: 34px;
                    height: 34px;
                    border-radius: @inputBorderRadius;
                    white-space: nowrap;
                    padding-left: 8px;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }
    }

    &:before {
      border-left: none;
    }
  }

  .age-container div>li {
    line-height: 32px;
  }

  .form .ondemand-box > label[for="other"] {
    padding-bottom: 0;
  }

  .cornerRow .devices-min-version-toggle {
    padding-top: 20px;
  }

  .annotatedList {
    .excludeTrigger {
      height:26px;
      position: absolute;
      top:0px;
      padding-top: 4px;
      left:5px;
      width:40px;
    }

    .excludeTrigger i.fa-check-circle {
      color: #3cbf6b;
    }
    .include-exclude-toggle {
      padding: 0 1px;
      height: 16px;
      margin-top:4px;
      width:25px;
    }
  }

  .twitterDeviceCheckedBox {
    font-weight: normal;
    color: #666;
  }

  .twitterDeviceCheckedBox::before {
    content: ' ';
    display:inline-block;
    padding: 6px;
    margin-right:5px;
    background-image: url(../../images/new/tw-checked.png);
    vertical-align: middle;
  }

  .Select.tailored-audience .Select-value span {
    color: #fff;
  }

  .Select.followers .Select-value a {
    color: #fff;
    text-decoration: underline;
  }

  .Select.followers .Select-value a:hover {
    color: #eee;
  }

  .react-select.followers .react-select__multi-value a {
    color: #fff;
    text-decoration: underline;
  }

  .react-select.followers .react-select__multi-value a:hover {
    color: #eee;
  }

  .followersCountMargin{
    margin-left: 7px;
    margin-right: 7px;
  }

  .followersLabel {
    margin-left: 7px;
  }

  .twFollowerBulkImg {
    display: inline-block;
  }
}

.ad-group-create .panel {
  .annotatedListSpacer {
    margin-top: 0 !important;
  }

  .keyword-targeting {
    .react-panel-content-margin .value .limit-max {

      .Select {
        .Select-control {
          cursor: default;
          .Select-value {
            cursor: pointer;
          }
        }
      }

      .keywordMatchPicker {
        color: #666;

        .ondemand-box {

          padding: 0 10px 0 0;
          background: white;

          .keyword {
            padding-top: 0;
            padding-left: 0;
          }
          .btn-group {
            vertical-align: middle;
          }

          .fa-times {
            line-height: 25px;
            cursor: pointer;
            color: #444;
            opacity: 0.4;
            font-weight: 300;
          }
        }

      }

      .keywordSuggestionsTrigger {
        position: static;
        float: unset!important;
      }
    }
  }
}

.copyFollowersButton {
  position: absolute;
  right: -25px;
  top: 3px;
  color: #BBBBBB;
  cursor: pointer;

  &:hover {
    color: #1997f0;
  }
}

.copyKeywordsButton {
  position: absolute;
  right: 0;
  top: -36px;
  color: #BBBBBB;
  cursor: pointer;

  &:hover {
    color: #1997f0;
  }
}
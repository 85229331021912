@import "ag-theme-senna";

.ag-bootstrap {
  .gridOverlay {
    background-color:#fff;
    left: 0;
    right: 0;
  }

  font-size: 13px;
  color: #707070;

  .ag-header-group-cell-label {
    text-align: center;
  }

  .ag-header,
  .ag-body,
  .ag-pinned-left-cols-viewport {
    border-bottom: 2px solid @detailsTableBorderColor;
  }

  .ag-body,
  .ag-pinned-left-cols-viewport {
    border-bottom-width: 1px;
  }

  .ag-pinned-left-cols-viewport {
    border-left: 1px solid @detailsTableBorderColor;
    border-right: 1px solid @detailsTableBorderColor;
  }

  .ag-header-cell {
    background: #fff;
    font-weight: bold;

    .ag-header-cell-label {
      padding: 7px 7px;
    }
  }

  .ag-header-cell,
  .ag-ltr .ag-header-cell,
  .ag-cell-not-inline-editing,
  .ag-ltr .ag-cell-last-left-pinned {
    border-right: 1px solid @detailsTableBorderColor;
    border-left: 1px solid @detailsTableBorderColor;
  }

  .ag-header-row {
    border-bottom: none;
  }

  .ag-cell-not-inline-editing {
    padding: 12px 7px;
    border-left: none;
  }

  .ag-icon-expanded,
  .ag-icon-contracted {
    display: inline-block;
    vertical-align: middle;
  }

  .ag-header .ag-pinned-left-header .ag-header-cell,
  .ag-cell.ag-first-column {
    text-align: right;
  }

  .ag-cell-not-inline-editing.text-left,
  .ag-header .ag-pinned-left-header .ag-header-cell.text-left{
    text-align: left;
  }

  .ag-row-odd,
  .ag-row-odd .ag-cell-not-inline-editing {
    background-color: #f4f7f9;
  }

  .ag-pinned-left-header .ag-header-row > div:not(.select-all-checkbox) {
    .ag-header-cell-label {
      background-color: @detailsHeadingBackground;
      border-bottom: 1px solid @detailsTableBorderColor;
      height: 100%;
    }
  }

  .ag-header-viewport {
    .ag-header-group-cell.ag-header-group-cell-with-group {
      font-size: 13px;
      text-transform: uppercase;
      border-right: 1px solid @detailsTableBorderColor;
      border-bottom: 1px solid @detailsTableBorderColor;
      border-left: 1px solid @detailsTableBorderColor;
      background-color: @detailsHeadingBackground;
    }

    .ag-header-cell.ag-header-cell-sortable {
      font-size: 13px;
      .totalValue {
        color:#999;
      }
      background-color: @detailsHeadingBackground;
    }

  }

  .ag-header-cell-label .ag-header-icon {
    margin-top: 2px;

    .fa {
      display: inline-block;
      visibility: visible;
    }
  }

  .ag-cell-highlight {
    background-color: rgb(204, 239, 255)!important;
    //border-right: 1px solid rgb(150, 150, 150)!important;
    //border-bottom: 1px solid rgb(150, 150, 150)!important;
  }

  .ag-cell-highlight-animation {
    transition: all 1s!important;
  }

  .ag-row-odd .ag-cell,
  .ag-row-even .ag-cell{
    &:hover {
      background-color: #f2f2f2;
    }
  }
}

.ag-grid-hide-scroll{
  .ag-horizontal-left-spacer{
    overflow-x: hidden !important;
  }
}

.details-screen {
  .ag-horizontal-left-spacer {
    opacity: 0;
  }

  .ag-menu {
    background-color: #00a1cb;
    color: white;
    border: 1px solid @input-border-color !important;
    margin-left: 13px;
    margin-top: -20px;

    .ag-menu-option-active {
      background-color: @catskillWhite !important;
    }

    &:before {
      content: none !important;
    }
  }

  .edit-overlay i.saveBtn {
    color: #1997f0;
  }

  .showOnHover {
    color: #1997f0;
  }

  .progress.ag-grid-progress {
    > .progress-bar{
      background-color: #1997f0;
    }

    border-radius: 25px;
  }

  .ag-bootstrap {
    margin-left: -13px;
    .ag-row-even .ag-cell.ag-cell-range-selected,
    .ag-row-odd .ag-cell.ag-cell-range-selected {
      background-color: #e5f6fe;
    }
  }

  .tw-adCellPreview {
    .toggle-show-segmented {
      position: absolute !important;
      left: 5px;
      top: calc(50% - 12px);

      &.fa-caret-down:hover, &.fa-caret-right:hover{
        color: black;
      }
    }

    cursor: pointer;
  }

  .toggle-show-segmented {
    float: left;
    font-size: 16px;
    color: #959595;
    width: 15px;
    cursor: pointer;
    padding: 5px;
  }

  .toggle-show-segmented.disabled {
    cursor: default;
    color: #ccc;
  }

  .toggle-show-segmented.fa-spinner {
    font-size: 14px;
    position: relative;
    margin-top: 5px;
    padding: 0;
  }

  .entityNameContainer {
    margin-left: 20px;
  }

  .segmentEntityNameContainer {
    padding-left: 18px;
    margin-left: 10px;
    height: 100%;
    border-right-color:rgb(206, 215, 221);
    border-right-style:solid;
    border-right-width:1px;
    border-bottom-color:rgb(228, 228, 228);
    border-bottom-style:solid;
    border-bottom-width:1px;

    div {
      padding-top: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:focus {
      outline-color:rgb(160, 208, 232);
      outline-offset:-1px;
      outline-style:solid;
      outline-width:1px;
    }

    .status-indicator {
      left: 10px;
    }
  }
}

.details-screen .ag-bootstrap .ag-row-even .ag-cell.ag-cell-focus:not(.select-all-checkbox):not(.select-checkbox) {
  overflow: hidden;
}

.details-screen .ag-bootstrap .ag-row-odd .ag-cell:not( .select-checkbox):not(.select-all-checkbox) .segmentEntityNameContainer {
  background: #f4f7f9 !important;

  &:hover {
    background-color: #f2f2f2!important;
  }
}

.details-screen .ag-bootstrap .ag-header .ag-pinned-left-header .ag-header-cell.select-all-checkbox {
  border-left: none;
}

.details-screen .ag-bootstrap .ag-row-even .ag-cell:not( .select-checkbox):not(.select-all-checkbox) .segmentEntityNameContainer {
  background: white !important;

  &:hover {
    background-color: #f2f2f2!important;
  }
}

.details-screen .ag-bootstrap .cell-creative-segment {
  background: white;
}

.details-screen .ag-bootstrap .ag-row-odd .ag-cell.ag-cell-range-selected.ag-cell-focus:not( .select-checkbox):not(.select-all-checkbox).cell-creative-segment {
  background: white!important;
}

.details-screen .ag-bootstrap .ag-row-hover {
  background-color: #F5F5F5;
}


.details-screen .ag-bootstrap .ag-pinned-left-cols-viewport .ag-cell.select-checkbox {
  background: 0 0;
  padding: 12px 17px;
  text-overflow: initial;
  line-height: 30px;
}

.details-screen .ag-bootstrap.ad .ag-pinned-left-cols-viewport .ag-cell.select-checkbox {
  padding: 17px 17px !important;
}

.twitter-style .details-screen .panel-body .ag-bootstrap:not(.ad_account) {
  position: relative;
  left: -40px;
  width: calc(~"100% + 40px");
}

.twitter-style .details-screen .ag-bootstrap .ag-ltr .ag-selection-checkbox,
.twitter-style .details-screen  .ag-bootstrap .ag-header-select-all {
  padding-right: 3px;
}

.creativesGrid .ag-cell-with-height {
  line-height: 40px !important;
}

.ag-cell-level-top .ag-group-value, .ag-group-value {
  font-weight: normal;
  cursor: pointer;
}

.details-screen .grid-preview-image.no-cursor {
  cursor: default;
}

.details-screen .ag-cell:hover .grid-preview-image.no-cursor {
  opacity: 1;
}

.ag-menu {
  border-radius: @borderRadius !important;
  border: 1px solid @input-border-color !important;
  padding: 8px 0;
  background: white !important;
  line-height: @line-height-base;
  margin-left: -100px;
  margin-top: -23px;

  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 9px;
    border-right-color: #00a1cb;
    top: 6px;
  }

  .ag-menu-option {
    cursor: pointer;
    color: #666;
    font-size: 12px;
    min-width: 80px;
    padding: 6px 11px!important;
    display: block;
  }

  span {
    color: #666!important;
    font-size:12px;
  }

  .ag-menu-option-active {
    background-color: #f5f8fa !important;
  }

  &:before {
    content: none !important;
  }
}

.admin-name-cell-renderer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

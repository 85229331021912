.modal-content {
  border-radius: @borderRadius;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 0;
}

.modal-dialog {
  margin-top: 80px;
}

.modal-header {
  background-color: @nav-bar-background-color;
  color: white;
  border-top-left-radius: @borderRadius;
  border-top-right-radius: @borderRadius;
  h4 {
    font-weight: 700;
  }

  .close {
    color: white;
  }
}

.modal-footer {
  background-color: white;
}

.modal-body {
  .btn-group.btn-group-sm {
    button {
      line-height: 32px;
      padding: 0px 25px;
    }
  }

  .dropdown.btn-group.btn-group-sm.btn-group-default {
    line-height: 34px;
    padding: 2px 0;
  }

  #Template, #Currency {
    border-radius: @inputBorderRadius;
    height: 34px;
    background-color: white;
    padding: 0 10px 0 10px;
    font-weight: 400;
  }
}

.media-preview {
  img, video {
    width: 680px;
  }

  .modal-body {
    padding: 10px;
  }
}
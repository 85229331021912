.twPreviewsContainer {
  color: #858585;

  .adPreview {
    position: relative;
    left: 0;
    right: 0;
  }

  iframe {
    padding-right: 15px;
    display: block;
    width: auto;
    height: auto;
    position: absolute;
    cursor: pointer;
    height: 100%!important;
  }

  .navigation-grid {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-row-gap: 30px;
  }

  .navigation-bottom-row {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .navigation-second-cell {
    grid-column-start: 2;
    display: flex;
    align-items: center;
  }

  .navigation-arrow {
    font-size: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    max-width: fit-content;
    height: 60px;
  }

  .fa-chevron-left {
    justify-self: flex-end;
    margin-right: 50px;
  }

  .fa-chevron-right {
    justify-self: flex-start;
    margin-left: 50px;
  }

  .blue {
    color: #1997f0;
  }

  .disabled-click {
    cursor: default;
    color: #bbb;
  }

  .small-navigation {
    font-size: 22px;
  }

  .disabled-click-blue {
    cursor: default;
    color: #9dd6ff;
  }

  .align-start {
    justify-self: flex-start;
  }

  .align-end {
    justify-self: flex-end;
  }

  .pages-number {
    align-self: center;
    margin-left: 10px;
  }

  .min-width100 {
    width: auto;
    min-width: 100px;
  }

  .dots {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 40px;
    flex-wrap: wrap;

    .dot {
      cursor: pointer;
      height: 15px;
      width: 15px;
      margin: 0 2px;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;
      transition: background-color 0.6s ease;
    }

    .active, .dot:hover {
      background-color: #717171;
    }

    .blue-dot {
      background-color: #9dd6ff;
    }

    .active-blue, .blue-dot:hover{
      background-color: #1997f0;
    }
  }
  .react-select__single-value {
    color: #858585;
  }
}

.tabbable, ul.nav-tabs.twitter-campaign-list  {
  border-bottom: 2px solid @header-border-color;
}

ul.nav-tabs.twitter-campaign-list{
  margin-left:0;
  margin-right:0;
  padding-left:0;
  padding-right:0;
}

ul.nav-tabs.nav {
  padding-top: 25px;

  &>.open {
    &>a {
      &:focus {
        background-color: transparent;
        border-bottom: 5px solid transparent;
        color: @submit-background-color;
      }
    }
  }
  &> li {
    &> a {
      background-color: transparent;
      display: inline-block;
      color: @fontColor;

      &:hover {
        background-color: transparent;
        border-bottom: 5px solid transparent;
        color: @submit-background-color;
      }
    }

    &+ li:before{
      content: " | ";
      color: @header-border-color;
      zoom: 1.5;

    }

    &.active > a {
      background-color: transparent;
      border-bottom: 5px solid @submit-background-color;
      color: @submit-background-color;

      &:hover {
        background-color: transparent;
        border-bottom: 5px solid @submit-background-color;
        color: @submit-background-color;
      }
    }

    & > .dropdown-menu {
      right: -200px;
      top: 0;
      left: initial;
      margin-top: 6px;
      border-radius: @borderRadius;
      box-shadow: none;
      padding: 8px 0;
      background: white;
      border: 1px solid @input-border-color;



      & > li {
        .dropdown-header {
          border-top: 1px solid @input-border-color;
          color: @fontColor;
          font-weight: 400;

          &:hover {
            background: @highlight-background-color;
            color: #333;
          }
        }

        &:first-child > .dropdown-header {
          border: none;
        }



        & > a {
          padding: 7px 23px;
          color: @darkerFontColor;

          & > .text {
            margin: 0;
          }

          &:hover {
            background-color: @highlight-background-color;
            color: @darkerFontColor;
          }
        }

        &.active {
          & > a {
            background-color: @highlight-background-color;
            color: @fontColor;
          }
        }
      }
    }

  }
}

.overview.content-bg-grey {
  padding: 25px;
}

.content-bg-grey {

  & > .panel-light:first-child {
    padding: 0;
  }

  #test-title {
    padding-left: 0;
  }

  #test-ad-button {
    margin-top: 5px;
  }

  .menu-create {
    padding-top: 6px;
    margin-top: -10px;
    /*.btn {
      margin-top: 0px;
    }*/
  }

  .panel-heading .menu-create {
    padding-top: 0;
  }

  &> .row {
    border-bottom: 2px solid @header-border-color;
    margin: 0 0 15px 0;

    //.btn-group {
    //  button, a {
    //    margin: 0;
    //  }
    //}
  }

  .dash-border-t2 {
    .twBulkActions {
      .hiddenIcon {
        padding-right: 5px;
      }

      .btn.btn-primary:disabled {
        background-color: @header-background-color;
        color: @fontColor;
        border-color: @border-color;
      }
    }
  }

  .autopilot {
    padding-bottom: 25px;
    margin-bottom: 25px;
  }

}



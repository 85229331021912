.adAccount-list {
    .pagination {
      margin-left: 20px;
    }

    .loader-campaignListStats {
        margin-top: 50px;
    }

    .loader-workspace-loader {
        position: absolute;
    }

    .adAccount-box {
        background-color: @catskillWhite;
        padding: 20px;
        border-bottom: none !important;
    }

    .content-bg-grey {
        div:first-child {
            border-bottom: none;
        }
    }

    .user-notif, .user-error {
        right: 40px !important;
        top: 25px !important;
    }

    .nav-tabs-icons + .nav-tabs-icons .user-notif {
        right: 75px !important;
    }

    .open > #agency-menu {
        background-color: @catskillWhite;
    }

    .account-menu {
        position: absolute;
        right: 30px;
        top: 23px;
    }

    .account-menu-toggle {
        border: none;
        padding: 0;

        i {
            font-size: 18px;
            color: @dodgerBlue;
            padding: 5px;

            &:hover {
                color: @scienceBlue;
            }
        }
    }

    .dropdown-menu-left-custom {
        top: -5px;
        left: auto;
        right: 20px;
    }

    .dropdown-menu-right-custom {
        top: -5px;
        right: auto;
        left: 20px;
    }

    @media (min-width: 1600px) {
        .twitter-add-account {
            min-width: 33.33333333333333% !important;
        }
    }

    @media (max-width: 1599px) {
        .twitter-add-account {
            min-width: 50% !important;
        }
    }

    @media (max-width: 1200px) {
        .adAccount-stats .stats-dot {
            display: none;
        }

        .adAccount-stats .active-campaigns {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .adAccount-box .adAccount-chart{
            margin-top: 40px;
        }

        .hide-text-of{
            display:block;
            overflow:hidden;
            text-overflow: ellipsis;
        }
    }

    .adAccount-box {
        margin-bottom: 30px;
    }

    .adAccount-box > a {
        text-align: left;
        width: 75%;
        position: relative;
    }

    .adAccount-box > a .adAccount-name {
        position: absolute;
        left: 60px;
        right: 0;
        top: 5px;
        margin: 0;
        width: auto;
        color: #1997f0;
    }

    .adAccount-stats {
        position: absolute;
        top: 50px;
        left: 95px;
        text-align: left;
    }

    .adAccount-stats > .inline-block:after {
        content: '  •  ';
        display: inline-block;
    }

    .adAccount-stats .inline-block {
        display: inline-block !important;
    }

    .adAccount-stats br {
        display: none;
    }

    .no-show-twitter {
        display: none;
    }

    .adAccount-chart {
        margin-top: 20px;
    }

    .adAccount-stats:empty + .show-only-twitter {
        position: absolute;
        top: 50px;
        left: 95px;
        text-align: left;
        display: block !important;
    }

    .show-only-twitter {
        display: none;
    }

    .adAccount-padding {
        padding: 0;
    }

    .adAccount-box .nav-tabs {
        margin-top: 0;
        padding-top: 0;
    }

    .inactive .adAccount-chart {
        display: none;
    }

    .panel-default .panel-heading {
        margin-left: 14px;
        .panel-title {
          font-weight: bold;
          text-transform: none;
        }
    }
}

// detailed grid
.text-left-middle-grid a,
.text-left-middle-grid span {
    font-weight:bold;
    color: @dodgerBlue;
    cursor: pointer;
}

.entityNameContainer .ad-preview {
    text-align: left;
    font-weight: bold;
    display: inline-block;
    color: #707070;
    cursor: default;
    vertical-align: middle;
    margin-bottom: -2px;
}

.entityNameContainer span.ad-preview:hover {
    text-decoration: none;
    color: #707070;
}

.text-left-middle-grid span:hover {
    text-decoration: underline;
    color: @dodgerBlue;
}

.text-left-middle-grid span.twOpenAdPreview:hover {
    text-decoration: none;
}

.text-left-middle-grid {
    line-height: initial;
}

@import '../twitterAds/variables';
@import "../bootstrap/variables.less";

.details-screen .ag-menu {
    border-radius: @borderRadius !important;
    padding:8px 0;
    background: white !important;
    line-height: @line-height-base;
}

.admin-dashboard .ag-menu {
    margin-left: 15px ;
}

.details-screen .ag-menu span {
    color: #666!important;
    font-size:12px;
}

.details-screen .ag-menu-separator {
  margin: 3px 0;
  display: block;
}

.details-screen .ag-menu-option {
    cursor: pointer;
    color: #666;
    font-size: 12px;
    min-width: 160px;
    padding: 7px 23px!important;
}

.details-screen .ag-menu-option-active {
    background: #c5d0d8!important;
}

.details-screen div.dropdown.nolinks.list-entity-menu{
  background: none;
  color: #c5d0d8;
  border:none;
}
.details-screen .tw-adCellPreview div.dropdown.nolinks.list-entity-menu{
    a{
        i {
            padding: 23px 10px;
        }
    }
}

.details-page-twitter .ag-header-icon.ag-sort-ascending-icon:after,
.ag-header-icon.ag-sort-descending-icon:after,
.ag-group-contracted:after,
.ag-group-expanded:after {
    display:none;
}
.details-page-twitter .ag-header-icon.ag-sort-ascending-icon,
.details-page-twitter .ag-header-icon.ag-sort-descending-icon,
.details-page-twitter .ag-header-icon.ag-sort-none-icon {
    display: block;
    margin-left:10px;
    margin-top:0px;
}

.details-page-twitter .select-checkbox {
    background: white !important;
}
.details-page-twitter .ag-selection-checkbox{
    padding-right:7px;
}

.details-page-twitter .ag-pinned-left-cols-viewport {
    border-left: none;
}

.details-page-twitter .ag-header-cell.select-all-checkbox {
    border-right: none;
    padding-right: 8px;
}

.details-page-twitter .ag-icon-checkbox-unchecked {
    background-image: url('../../images/new/tw-unchecked.png');
}

.details-page-twitter .ag-icon-checkbox-checked {
    background-image: url('../../images/new/tw-checked.png');
}

.details-page-twitter .ag-icon-checkbox-indeterminate {
    background-image: url('../../images/new/tw-indeterminate.png');
}

.details-page-twitter .disabled_link{
    color: #7d7d7d;
    cursor: initial;
    font-weight: normal;
}

.details-page-twitter .disabled_link:hover{
    color:#7d7d7d;
    text-decoration: none;
}

.details-page-twitter .menu_dropdown {
    cursor: default;
}

.details-page-twitter .menu_dropdown > .fa.menu_dropdown:hover {
    color: @dodgerBlue;
    cursor: pointer;
}

.twitter-style {
  @media (min-width: 1200px) {
      .workspaces-column {
        &-entity, &-new {
          width: 25% !important;
        }
      }
  }

  @media (min-width: 1600px) {
      .workspaces-column {
        &-entity, &-new {
          width: 16.66666666666667%!important;
        }
      }
  }
  .adAccount-list {
    .adAccount-box {
      background-color: @catskillWhite;
      padding: 20px;
      border-bottom: none !important;
      border-radius: 12px;
    }
  }

  .workspaces-row {
    //height: 150px;
    height: 50px;

    .workspaces-column {
      &-entity {
        height: 100%;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 20px;

        .workspace-box {
          background: @catskillWhite;
          border-left: 4px solid @loblolly;
          border-bottom-right-radius: 12px;
          border-top-right-radius: 12px;
          height: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          .name {
            padding: 10px 12px;//10 was 6 before
            overflow: hidden;
            text-overflow: ellipsis;
            color: @dodgerBlue;
            font-size: 20px;
            font-weight: 700;
            cursor: pointer;
            width: 100%;
            white-space: nowrap;
            //border-bottom: 2px solid @loblolly;
          }

          .graphs {
            width: 100%;
            height: calc(100% - 42px);
            display:flex;

            .impressions {
              width: calc(50% - 3px);
              height: 100%;
              float:left;
            }

            .spent {
              width: calc(50% - 3px);
              height: 100%;
              border-left: 2px solid @loblolly;
            }

            .label {

            }

            .chart {

            }
          }
        }
      }

      &-new {
        height: 100%;
        padding-left: 15px;
        padding-right: 15px;

        .workspace-box {
          background: white;
          border: 2px solid @catskillWhite;
          border-radius: 12px;
          width: 100%;
          height: 100%;
          position: relative;

          .submit {
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      &-loading {
        height: 100%;
        padding-left: 15px;
        padding-right: 15px;

        .workspace-box {
          background: white;
          border: 2px solid @catskillWhite;
          border-radius: 12px;
          width: 100%;
          height: 100%;

          .text-center {
            position: relative;
            width: 100%;
            height: 100%;

            div {
              width: 100%;
              height: 100%;

              .loader-loading {
                //margin: 0;
                margin-top: 10px;
                position: absolute;
                //top: calc(50% - 20px);
                //top: 50%;
                //left: calc(50% - 20px);
                //left: 50%;
                //transform: translate(-50%, -50%);
              }
            }
          }
        }
      }

    }
  }
}

.rules {
  .autopilot {
    .controls {
      ul > li {
        & > a {
          .btn-default;
          padding: 6px 8px;
          font-size: 12px;
          text-decoration: none;
          font-weight: bold;
        }
      }
    }
    .overview .header .info h5 {
      color: @dodgerBlue;
    }

    .details {
      .optimisation-goal>div {
        label {
          line-height: 27px;
        }

        .amount-input {
          input, span {
            top: -13px!important;
          }
        }

        .autopilot-optimization-bellow {
          top: -13px!important;
        }
      }
    }

    .amount-input input {
      line-height: 22px;
    }

    .details {
      a.panel-title {
        text-transform: uppercase;
        color: black;
      }
    }
  }

  .btn-group-sm>.btn, .btn-sm {
    padding: 4px 10px;
    font-size: 12px;
    &:hover {
      padding: 4px 10px;
      font-size: 12px;
    }
  }

  .single-rule-container {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .single-rule-container {
    .controls {
      li {
        a {
          .rounded-btn-default;
          border: 1px solid @submit-background-color;
          padding: 5px 12px;
          font-weight: bold;
          font-size: 13px;

          &:hover {
            background-color: @defaultButtonHoverColor;
            border-color: @submitButtonHoverColor;
            text-decoration: none;
          }

        }
      }

      .popoverLabel {
        height: 27px;
      }

      a.disable-edit {
        color: @input-border-color !important;
        background-color: @header-background-color !important;
        border-color: @input-border-color !important;

        &:hover {
          cursor: not-allowed;
        }
      }

      li:first-child a {
        .rounded-btn-primary;
        &:hover {
          color: #fff;
          background-color: @submitButtonHoverColor;
          border-color: @submitButtonHoverColor;
          text-decoration: none;
        }
      }
    }
  }

  .panel-title {
    text-transform: uppercase;
  }

  .rules-edit .rules-edit-panel .panel-body .form label.caption {
    text-transform: capitalize;
  }

}

.rules-list {
  max-width: 1007px;
}

.rules-edit {
  .select2-container {
    .select2-choice {
      height: 34px;
      line-height: 32px;
    }
  }
}

.rules .rules-edit .rules-edit-panel .panel-body .form div.value {
  margin-left: 0;
}


.rules.reports-list {
  .header h3 {
    overflow: hidden;
    margin-top: 15px;
  }

  .menu-action {
    bottom: initial;
    position: relative;
    left: inherit;
    right: inherit;
    top: inherit;
    display: flex;
    align-items: center;
    overflow: hidden;
    min-height: 40px;
    padding: 10px 20px 10px 0;
    li:first-child {
      padding-left: 0;
    }
  }
}

.rules {
  .autopilot,
  .rules-group,
  .budget-limit {
    .autopilot-box {
      width: 380px;
      padding: 15px;
      background-color: @catskillWhite;
      border-radius: 8px;

      .autopilot-box-header {
        color: @shuttleGray;
        padding-bottom: 20px;
      }

      .autopilot-box-button {
        .btn{
          font-weight: 400;
        }

        #fb_add_new_ads_button .title {
          text-transform: none;
          font-size: 13px;
        }

        .menu-create {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}



#campaignSummary {
  background-color: @nav-bar-background-color;
  color: white;

  .arrow {
    border-right-color: @nav-bar-background-color;
    &:after {
      border-right-color: @nav-bar-background-color;
    }
  }

  .txt-label {
    color: @input-border-color;
  }
}
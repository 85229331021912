.dashboard-page-tw {
  .panel .text-metric-overview {
    background-color: transparent;
  }

  .text-metric {
    width: 100%;
    text-align: center;
    background-color: @chartsBackground;
  }

  .external-dashboard {
    padding-top: 20px;
  }

  .highcharts-container {
    background-color: @chartsBackground;
  }

  .dashboard-clean{
    .highcharts-container {
      background-color: transparent;
    }
  }

  .ag-bootstrap {
    .ag-cell-not-inline-editing {
      padding: 0 7px;
    }
  }

  .ag-header-icon.ag-sort-ascending-icon,
  .ag-header-icon.ag-sort-descending-icon,
  .ag-header-icon.ag-sort-none-icon {
    display: block;
    margin-left: 10px;
    margin-top: 0;
  }

  .ag-cell-not-inline-editing.text-left,
  .ag-header .ag-pinned-left-header .ag-header-cell.text-left {
    text-align: left;
  }

  .ag-bootstrap .ag-pinned-left-header .ag-header-row > div:not(.select-all-checkbox) .ag-header-cell-label {
    background-color: #e9ecef;
    border-bottom: 1px solid #ced7dd;
    height: 100%;
  }

  .ag-header {
    .ag-header-cell-label {
      display: flex;
      justify-content: flex-end;

      .ag-header-cell-text {
        text-align: right;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .ag-pinned-left-header .ag-header-cell {
      .ag-header-cell-label {
        display: flex;
        justify-content: flex-start;

        .ag-header-cell-text {
          text-align: right;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .ag-header-cell-text .displayName {
    padding-left: 10px;
  }

  .ag-header-cell-label .ag-header-cell-text,
  .ag-ltr .ag-header-group-text {
    font-weight: normal;
    text-transform: initial;
  }
}

.dashboard-sum-row .row{
  padding: 15px 0 15px 0;
  background-color: #FAFAFA;
}

.dashboard-clean {
  .widget-wrapper:first-child {
    border-bottom: 5px solid #f0f0f0;
    padding: 20px 20px 50px 20px;
  }

  .widget-wrapper:nth-child(2) {
    border-bottom: 5px solid #f0f0f0;
    padding: 40px 20px 80px 20px;
  }

  .widget-wrapper:nth-child(n+3) {
    border-bottom: 5px solid #f0f0f0;
    padding: 40px 20px 40px 20px;
  }
}

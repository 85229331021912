.dropdown.btn-group .bootstrap-select-react {

  &.btn:first-child,
  &.btn:last-child {
    border-radius: 3px;
  }

  &.btn.btn-default {
    box-shadow: none;
    border-color: #d5d5d5;
    background-color: #fff;
    color: #858585;
    font-weight: normal;
    line-height: 31px;
    height: 34px;
  }

  &.btn.btn-primary {
    color: #fff;
  }
}
.form .ondemand-box {
  padding: 0;
  border: 1px solid @input-border-color;
  border-radius: @borderRadius;
  background: white;

  &.devices {
    margin-top: 0;

    .react-select-wrapper {
      display: inline-block;
    }
  }

  &.placements>div:last-child {
    padding-top: 0;
  }

  &> p, &>div {
    padding-left: 20px;
    margin: 0;
    &:first-child {
      padding-top: 20px;
    }

    &:last-child {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  &> label {
    padding-left: 20px;
    padding-top: 20px;
    & + br + div {
      padding-left: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid @header-border-color;

      .cornerRow {
        .precise-devices-specific-device-select {
          float: none;
          padding-right: 20px;
        }

        &:first-child {
          .Select-value-label {
            line-height: 32px;
          }
        }
      }
    }
  }

  &> label[for=other] {
    padding-bottom: 20px;
  }

  .devices-min-version-toggle {
    top: -14px;
  }

  .devices-min-version-select {
    top: -14px;
  }

  &:before {
    content: none;
  }
}

.connect-accont-panel > .panel{
  margin: 0 !important
}

.ad-group-create .panel
.panel-body.followers-targeting
.react-panel-content-margin
.form.top-spacer-10 .value .bulk-select
.ondemand-box.ondemand-box-noTopArrow
.twFollowersSelectionContainer {
  margin-bottom: 10px;
}

.devicesAdvanced {
  padding-top: 10px;
  text-transform: capitalize;
}

.less.devices {
  margin-top: 0;
}

.frequency_cap {
  margin: 10px 0;

  .react-select-wrapper {
    margin-top: 10px;
  }

  .input-group input {
    width: 100% !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .input-group span {
    color: #858585;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
  }
}

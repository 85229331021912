@import '../twitterAds/variables';
@import '../twitterAds/fonts';
@import '../twitterAds/login';
@import '../twitterAds/inputs';
@import '../twitterAds/buttons';
@import '../twitterAds/nav';
@import '../twitterAds/header';
@import '../twitterAds/tabs';
@import '../twitterAds/plugins/select-multi-tag';
@import '../twitterAds/plugins/ag-grid';
@import '../twitterAds/daterange';
@import '../twitterAds/tw-grid';
@import '../twitterAds/tw-tab-icons';
@import '../twitterAds/plugins/autopilot';
@import '../twitterAds/plugins/fixed-data-table';
@import '../twitterAds/alerts';
@import '../twitterAds/panels';
@import '../twitterAds/acc-list';
@import '../twitterAds/ad-group';
@import '../twitterAds/ad-group-list';
@import '../twitterAds/error500';
@import '../twitterAds/charts';
@import '../twitterAds/plugins/select-single';
@import '../twitterAds/text-metric';
@import '../twitterAds/plugins/annotated-list';
@import '../twitterAds/plugins/modal';
@import '../twitterAds/plugins/ondemand-box';
@import '../twitterAds/timepicker';
@import '../twitterAds/plugins/floater-estimates';
@import '../twitterAds/channels';
@import '../twitterAds/filter';
@import '../twitterAds/menu';
@import '../twitterAds/create-ads';
@import '../twitterAds/plugins/campaign-tooltip';
@import '../twitterAds/main-navigation-menu';
@import '../twitterAds/changelog';
@import '../twitterAds/side-menu';
@import '../twitterAds/report-form';
@import '../twitterAds/u-submit-form';
@import '../twitterAds/overview';
@import '../twitterAds/dashboard';
@import '../twitterAds/ab-test';
@import '../twitterAds/report-grid';
@import '../twitterAds/metrics-picker';
@import '../twitterAds/language-changer';
@import '../twitterAds/plugins/bootstrap-select-react';
@import '../twitterAds/import-modal';
@import '../twitterAds/level-switcher';
@import '../twitterAds/action-row';
@import '../twitterAds/invoice';
@import '../twitterAds/popover';
@import '../twitterAds/rules';
@import '../twitterAds/form';
@import '../twitterAds/calendar';
@import '../twitterAds/lockscrn';
@import '../twitterAds/facelift/theme-customisation';
@import '../twitterAds/notification-list';
@import '../twitterAds/reports';
@import '../twitterAds/progress';
@import '../twitterAds/workspace';
@import '../twitterAds/plugins/date-range-picker';
@import '../twitterAds/import-container';
@import '../twitterAds/carousel';
@import '../twitterAds/url-tags';
@import '../twitterAds/audience-explorer';
@import '../twitterAds/scheduling';

body,
.content-bg-grey,
.footer {
  background: white;
  color: @darkerFontColor;
}

.hiddenLabel {
  display: inline;
  visibility: visible;
  padding-left: 5px;
}

.campaign-list {
  padding: 35px 25px 35px 15px;
}

.channel-box {
  border-radius: @borderRadius;
}

.tw-text-capitalize {
  text-transform: capitalize;
}

.label-danger {
  margin-bottom: 15px!important;
  display: inline-block;
  border-radius: 8px;
}

.hiddenTwitter {
  visibility: hidden;
  display: none;
}

.hiddenIcon {
  display: inline;
  visibility: visible;
}

.main-container {
  min-width: 1024px;
}

.page-header {
  h1, .h1 {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 400;
}

.jumbotron {
  p {
    font-weight: 300;
  }
}

.annotatedList li.annotatedListUnselectable {
  font-weight: 700;
}

.report-metrics-picker {
  & > div {
    .metrics-column {
      .columns-container {
        .category-header {
          font-weight: 700;
        }
      }
    }
  }
}

#test-ad-button {
  .title {
    padding-left: 3px;
  }
}

.panel-default .panel-heading .panel-title {
  font-weight: 300;
}

#action-overlay-date-range {
  span:nth-child(2) {
    font-weight: normal;
  }
}

a,
a:focus {
  color: @dodgerBlue;
}

a:hover {
  color: @rollingStone;
}

.ad-group-create .react-panel-content-margin .suggestion-select div {
  right: unset;
}

body {
  background-color: white;
}

.navbar {
  background: @nav-bar-background-color;
  border-color: @header-background-color;

  .alert.alert-warning {
    border-radius: 0;
  }

  &>.container .navbar-brand {
    padding: 6px 13px 0 0;
  }

  &-breadcrumb {
    border-left: none;
    margin-bottom: 0;
    height: 46px;

    .breadcrumb {
      padding: 0;
      background-color: @nav-bar-background-color;
      height: 46px;

      .entity-jump {
        height: 46px;
      }


      & > li {
        line-height: 46px;
        &.entity-jump {
          vertical-align: baseline;

          &:before {
            line-height: 46px;
          }
        }

        &:last-child {
          vertical-align: top;
        }


        vertical-align: top;
        &> a {
          line-height: 46px;
        }

      }

      a {

        &:hover {
          color: white;
        }

        &:active {
          color: white;
        }
      }

      .select2-container {
        margin-top: 0;
        height: 46px;
        vertical-align: top;
      }

      .select2-choice {
        background-color: @nav-bar-background-color;
        border-bottom: none;
        line-height: 46px;
        padding: 0;
        height: 46px;

        &:hover {
          color: white;
        }
      }

      .hide-choosen {
        height: 46px;
      }
    }
  }

  &-header {
    .navbar-tools.user-notifications {
      display: none;
    }
  }
}

#navbar-workspace {
  max-width: 290px;
  height: 100%;
  float: right;
  padding: 6px 0;

  button {
    background: @nav-bar-background-color;
    border-color: white;
    color: white;

    &.animate-workspace {
      box-shadow: 0 0 0 0 rgba(white, 1);
      animation: shadow-pulse 0.8s;
      animation-iteration-count: 2;
    }
  }
}

#navbar-dashboard-filters {
  width: fit-content;
  height: 100%;
  float: right;
  padding: 6px 0;
  font-size: 13px;
  display: flex;

  .Select-control {
    background: transparent;
    border-color: white;
    border-radius: 20px !important;
    color: white;
    text-align: center;
    width: 290px;

    .Select-value-label {
      color: white;
    }

    .Select-arrow{
      border-top-color: white;
    }
  }
}

.select2-container {
  .select2-choice {
    background-color: transparent;
    border-radius: @inputBorderRadius;
    box-shadow: none;
    border-bottom: 1px solid @input-border-color;

    &:hover {
      background-color: transparent;
      border-color: @input-border-color;
    }
  }
}


.select2-drop.select2-display-none.select2-with-searchbox.select2-drop-active{
  margin-top: 8px;
  padding-top: 8px;
  border-radius: @borderRadius;
  border: 1px solid @input-border-color;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.select2-results {
}

.navbar > .container .navbar-brand, .logo {
  font-family: 'Roboto', sans-serif;
}

.navbar-tools .dropdown-menu li a .time {
  font-weight: 700;
}

.navbar-fixed-top {
  &.affix {
    .box-shadow(none);
  }
}

.dashboard-filter-label {
  font-size: 13px;
  color: white;
  font-family: Roboto, sans-serif;
  margin: auto 20px auto 25px;;
  padding-top: 3px;
  font-weight: bold;
}

.action-overlay-content{
	.notifications {
		display: flex;
		height: 100%;

		.content-bg-grey {
			width: 100%;
		}
	}

	.notifications .entity-notifications {
		.ag-header-row {
			color: #707070;
			font-family: 'Roboto', sans-serif;
			white-space: normal;
			font-weight: bold;

			.ag-header-cell:not(:first-child) {
				background-color: #e9ecef !important;
				border-bottom: 1px solid #c4c4c4 !important;
			}

			.ag-header-cell:nth-child(2) {
				padding-left: 25px;
			}
		}

		.ag-header-row .ag-header-cell {
			padding: 10px 0 8px 0;
		}

		.ag-row {
			.ag-cell {
				padding: 12px 0;
				border-bottom: 1px solid #ccc;
			}

			.ag-icon-checkbox-checked {
				background-image: url('../../images/new/tw-checked.png');
			}

			.ag-icon-checkbox-unchecked {
				background-image: url('../../images/new/tw-unchecked.png');
			}
		}

		.content-bg-grey > .row {
			margin: 0;
			border: 0;
		}

		.btn {
			margin-right: 7px;
            font-size: 12px;
            font-weight: normal;
            padding: 2px 10px;
            line-height: 20px;
		}

		.unread {
			background-color: #f4f7f9;
		}

		.httpool-checkbox-button {
			margin-left: -60px;
		}

		.notif-buttons {
			margin-bottom: 25px;
			margin-left: 30px;
		}

		.notifications-grid {
			display: block;
			overflow-y: auto;
			height: calc(100% - 100px);
		}

		height: 100%;

		.ag-row-stub {
			visibility: hidden;
		}

		.notifications-loading {
			background: rgba(256, 256, 256, 0.8);
			height: 678px;
			width: 975px;
			color: #000000;
			margin-top: 50px;
			margin-left: 20px;
		}
	}
}

.cell-wrap-text {
	white-space: normal !important;
}

// Buttons
.rounded-btn {
  border-radius: 9999px;
  font-weight: 700;
  font-size: 13px;
  font-family: Roboto, sans-serif;
}

.rounded-btn-primary {
  background-color: @submit-background-color;
  border-color: @submit-background-color;
  border-width: 1px;
  font-size: 13px;
  border-radius: 9999px !important;
  color: white;
  font-family: Roboto, sans-serif;
}

.rounded-btn-default {
  color: @submit-background-color;
  border-color: @submit-background-color;
  font-size: 13px;
  border-width: 1px;
  border-radius: 9999px;
  border-style: solid;
  font-family: Roboto, sans-serif;
}

.submit {
  font-size: 13px;
  .rounded-btn-primary;
}

.cancel-link,
.cancel-button.back_btn,
.remove-input.btn-minus,
.view-all.is-navbar,
.cancel-button,
.list-action-btn.dropdown-toggle {
  .rounded-btn-default;
  background-color: transparent;
  border-radius: 9999px !important;
  font-size: 13px;

  &:hover {
    color: @submitButtonHoverColor;
    background-color: @defaultButtonHoverColor;
    border-color: @submitButtonHoverColor;
  }
}

.list-action-btn {
  color: @submit-background-color !important;
  border-color: @submit-background-color !important;
}

.cancel-link {
  padding: 6px 12px;
}

.btn {
  .rounded-btn;
  font-family: Roboto, sans-serif;
}

.btn-icon .badge {
  font-family: Roboto, sans-serif;
}

.btn-primary.active,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.open > .dropdown-toggle.btn-primary,
.btn-primary,
.btn-link-water,
.btn-submit,
.add-input.btn-plus,
.submit {
  .rounded-btn-primary;

  &:hover {
    color: #fff;
    background-color: @submitButtonHoverColor;
    border-color: @submitButtonHoverColor;
  }

  &[disabled] {
    color: @input-border-color !important;
    background-color: @header-background-color !important;
    border-color: @input-border-color !important;
  }
}

#dropdown-multiselect-preset-multiselect {
  font-size: 12px;
}

.btn-default {
  color: white;
  .rounded-btn-default;

  &:hover {
    color: @submitButtonHoverColor;
    background-color: @defaultButtonHoverColor;
    border-color: @submitButtonHoverColor;
  }
}

.selected-date-range-btn.btn {
  border-radius: @inputBorderRadius;
  border-color: @input-border-color;
  border-width: 1px;
}

.campaign-list .twBulkActions {
  .action-row .btn {
    font-weight: 400;
  }
}

.campaign-list .twBulkActions .btn-primary {
  color: white !important;

  &:hover {
    background-color: @submitButtonHoverColor;
    border-color: @submitButtonHoverColor;
  }

  &[disabled] {
    color: @input-border-color !important;
    background-color: @header-background-color !important;
    border-color: @input-border-color !important;
  }
}

.dimensions-metrics-picker #download-button,
.metric-picker .btn-sm,
.dimensions-metrics-picker .btn-sm {
  font-size: 12px;
  padding: 2px 10px;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 2px 10px;
  font-size: 12px;

  &:hover {
    padding: 2px 10px;
    font-size: 12px;
  }

  &:focus {
    padding: 2px 10px;
    font-size: 12px;
    outline: none;
  }
}

.twBulkActions button {
  margin-right: 7px;
}

.twCardsModal .btn-group {
  .btn {
    &:only-child {
      border-radius: 9999px;
    }
  }

  .inline-block {
    padding-left: 10px;
  }
}

.twModal .btn-group {
  .btn {
    &:last-child {
      border-radius: 9999px;
    }
  }

  .inline-block {
    padding-left: 10px;
  }
}

.btn-group {
  .btn {
    &:first-child {
      border-radius: 0;
      border-top-left-radius: @borderRadius;
      border-bottom-left-radius: @borderRadius;
    }

    &:last-child {
      border-radius: 0;
      border-top-right-radius: @borderRadius;
      border-bottom-right-radius: @borderRadius;
    }
  }

  .btn.btn-default {
    box-shadow: none;
    border-color: @border-color;
    background-color: @highlight-background-color;
    color: @nav-bar-background-color;
  }

  .active.btn.btn-default {
    border-color: @submit-background-color;
    background-color: @submit-background-color;
    color: white;
  }
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0 !important;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: -1px;
}

.card-button {
  font-weight: 400;
  border-radius: 9999px;
  border: 1px solid #1da1f2;
  background: #fbfbfb;
  color: #1da1f2;
}

.toggleButton > .btn-group > span:first-child > .btn {
  border-radius: 8px 0 0 8px;
  outline: none;
}

.toggleButton > .btn-group > span:last-child > .btn {
  border-radius: 0 8px 8px 0;
  outline: none;
}

// Dropdown component use button/button-group
.dropdown.btn-group .btn:first-child,
.dropdown.btn-group .btn:last-child {
  border-radius: 8px
}

.modal-body .dropdown.btn-group.btn-group-sm button {
  line-height: 27px;
  padding-left: 10px;
}

.btn:active:focus, .btn:focus {
  outline: none;
}

.btn-group .bootstrap-select-react.btn-link,
.btn-link {
  background: transparent !important;
  border: 0;
  box-shadow: none;
  border-radius: unset !important;
  color: @dodgerBlue !important;
  padding-right: 20px;
  width: 100%;
}

.btn-link:hover {
  text-decoration: none;
  color: @scienceBlue !important;;
}

.btn-link:focus {
  color: @dodgerBlue !important;
  text-decoration: none;
}


.menu-create .btn .title {
  text-transform: inherit;
  font-size: 12px;
  padding-top: 0;
}

.menu-create .btn .fa {
  padding-top: 3px;
}

.btn.text-nowrap {
  white-space: nowrap;
}


.header-filter-flat .filter-item {
  .dropdown.btn-group .btn:first-child {
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    border-color: @dodgerBlue;
    border-right-color: transparent;

    &:hover {
      background: @scienceBlue;
      border-color: @scienceBlue;
      border-right-color: transparent;
    }
  }
}


.flex-dropdown .dropdown.btn-group {
  display: flex;

  & .btn:first-child {
    flex: 1;
  }
}



.header-filter-flat .filter-item .dropdown.btn-group button:last-child {
  border-color: @dodgerBlue;
  border-left-color: white;

  &:hover {
    background: @scienceBlue;
    border-color: @scienceBlue;
    border-left-color: white;
  }
}

.new-entity-buttons-widget {
  margin: 20px 20px;
  font-weight: 700;

  .new-entity-button-container {
    margin: 0;
    margin-bottom: 10px;
    margin-right: 20px;

    &:last-child {
      margin: 0;
    }

    .submit {
      float: unset !important;
      font-weight: 700;
      width: 170px;
      padding: 9px 9px;
    }
  }
}

.filter-container {
  .twLoader {
    font-size: 1em;
  }
}

.btn-remove-tweet-body {
  margin-left: 7px;
}

.btn-add-tweet-body {
  margin-left: 6px;
}

.url-tracker-btn {
  font-size: 12px !important;
}

@linkWater: #d6e8f4;

@import "../bootstrap/variables.less";
@import "../bootstrap/mixins.less";

.btn-link-water {
  .button-variant(@dodgerBlue, @linkWater, @linkWater);
}

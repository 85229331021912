@import 'plugins/scrollbar';

.report-metrics-picker {
  height: 100%;
  margin: 0;

  & > div {
    height: 100%;

    .categories-column {
      width: 250px;
      display: inline-block;
      vertical-align: top;
      overflow-y: scroll;
      height: 100%;

      &> div.category-list  {
        padding: 0;

        &.active {
          border-left: none;
          padding-left: 0;
        }

        & ul > div {
          padding: 4px 0px;
          margin: 0 10px;
          border-bottom: 1px solid #e9eaeb;

          a {
            padding: 8px 16px;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 400;
            color: black;
            font-size: 14px;
            line-height: 18px;

            &:not(.sub-category) {
              text-transform: uppercase;
              color: @rollingStone;
              font-size: 12px;
            }

            &.sub-category {
              padding: 8px 16px;
              font-weight: 400;
              color: black;
              font-size: 14px;

              &.active {

                padding: 8px 16px;
                color: @dodgerBlue;
                font-weight: 400;
              }
            }

            &.active {

              padding: 8px 16px 8px 16px;
              color: @dodgerBlue;
              font-weight: normal;
            }
          }
        }
      }
    }

    .metrics-column {
      width: 400px;
      height: 100%;
      display:inline-block;

      .search-filter-container {
        padding: 19px 0 0 19px;
        height: 70px;
        display: block;
        background-color: @catskillWhite;

        position: fixed;
        width: 390px;
        z-index: 1;

        .search-filter {
          border: 1px solid #c5d0d9;
          width: 210px;
          padding: 3px 5px;
          background-color: white;
          border-radius: 3px;

          input {
            padding: 3px 5px;

            &:focus {
              box-shadow: none;
              border: 1px solid #c5d0d9;
            }
          }
        }
      }

      .columns-container {
        display: block;
        height: 100%;
        overflow-y: scroll;
        position: relative;
        padding-top: 70px;

        .category-header {
          background-color: transparent;
          padding: 5px 8px;
          font-size: 12px;
          font-weight: 400;
          border-bottom: 1px solid @athensGray;

          &> span {
            text-transform: uppercase;
            color: @rollingStone;
          }

          .select-all {
            display: inline-block;
            float: right;
            font-size: 10px;
            font-weight: 400;
            line-height: 16px;
          }
        }

        .root-values {
          padding: 2px 8px;

          label {
            margin-bottom: 0;
          }
        }

        .sub-category-header {
          padding: 5px 8px;
          text-transform: uppercase;
          font-size: 10px;
          border-bottom: none;
        }

        .values {
          padding: 2px 8px;
          label {
            margin-bottom: 0;
          }
        }
      }
    }

    .metrics-order {
      width: 250px;
      display: inline-block;
      vertical-align: top;
      background-color: @catskillWhite;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;

      .card {
        cursor: initial;
        background-color: white;
        width: 90%;
        margin: 7px 5%;
        padding: 6px;

        &.draggable {
          cursor: move;

          i {
            line-height: 100%;
            margin-right: 10px;
            margin-left: 5px;
            color: @iron;
          }

          .text {
            padding-left: 0;
            width: 77%;
            vertical-align: middle;
            display: inline-block;
            position:relative;
            top: -2px;
          }

          i.fa-close {
            cursor: pointer;
            color: black;
          }

          &:hover {
            cursor: move;
          }
        }



        .text {
          padding-left: 20px;
          display: inline-block;
        }

        &.is-dragging {
          border: 1px dashed black;
          color: white;

          i {
            color: white;
          }

          i.fa-close {
            color: white;
          }
        }

      }

      [draggable=true] {
        opcity: 1;
      }
    }
  }
}

.withDimension {
  height: 100%;
  .report-metrics-picker {
    height: calc(100% - 80px);
  }
  .report-dimensions{
    height: 80px;
    padding: 0 20px 20px;
  }
}

div#reports-metrics-picker {
  border-radius: 3px;
  border: 1px solid #d3d3d3;
  width: 957px;

  .categories-column {
    width: 31.4136%;
  }

  .metrics-column {
    width: 37.1727%;

    .columns-container {
      width: 100%;
    }
  }

  .metrics-order {
    width: 31.4136%;
  }

  .search-filter-container {
    position: relative;
    width: calc(100% - 12px);

    div.search-filter {
      width: 210px;

      i {
        line-height: 24px;
      }
    }
  }
}

.custom-metrics-modal {
  min-width: 902px;
  height: 90%;


  .modal-content {
    height: 100%;

    .modal-body {
      height: calc(~'100% - 75px - 56px');
      padding: 0;
    }

    .align-left {
      float: left;
    }

    .save-preset {
      line-height: 29px;
    }

    .modal-footer {
      input {
        padding: 6px 10px;
        margin-left: 10px;

        &:focus {
          box-shadow: none;
          border: 1px solid #c5d0d9;
        }

        &:hover {
          box-shadow: none;
          border: 1px solid #c5d0d9;
        }
      }
    }

    .modal-header {
      border-bottom: none;
    }
  }
}

.report-metrics-picker-label > label {
  max-width: 310px !important;
}

.is-deprecated-metric {
  text-decoration: line-through;
  text-decoration-color: #788087;
}

.main_holder {
  margin: 0;
  width: 100%;

  .header {
    background-color: @nav-bar-background-color;
    margin: 0;
    padding: 4px 0;

    img {
      width: 150px;
    }
  }

  .content {
    width: 33.33333%;
    margin: 100px auto;
    border: 1px solid @border-color;
  }
}
.action-row {
  width: 100%;
  height: 50px;
  margin: 5px 0 5px -10px;

  &>div:not(.twBulkActions) {
	float: right !important;
	margin: 14px 10px 0 -5px;
  }

  &>label.switch {
	float: right !important;
	margin: 10px -10px 0 0;
  }

  .twBulkActions {
	float: left !important;
	margin: 13px -15px 13px 37px;
	padding-top: 0;

	&>div {
	  display: inline-block;

      .add-entity-button {
        display: inline-block;
      }
	}
  }

  .create-new-entity {
	display: inline-block;

	a {
	  margin-top: 0;
	  margin-right: 7px;

	  span.title {
		text-transform: none;
	  }
	}
  }

  .create-new-entity-group button#test-ad-button {
	margin-top: 0;
  }

  .btn-group.open .dropdown-toggle {
	box-shadow: none;
  }

  .btn {
	font-weight: 400;
  }

  #test-title, #add-twitter-group, #add-twitter-ads {
	text-transform: none;
  }

  #test-title {
	margin-left: 0;
  }

  #breakdown {
    height: 22px;
    box-shadow: none;
    padding-right: 20px;
    width: 100%;
    font-size: 12px;

    b {
      font-size: 11px;
    }
  }

  #breakdown, #export-excel, #dropdown-multiselect-preset-multiselect {
	background-color: #fff;
	color: @dodgerBlue;
	border: none;
	&:hover, &:focus, &:active {
	  background-color: #fff !important;
	  color: @scienceBlue !important;
	  border: none;
	}
  }

  .dropdown-toggle.btn-primary {
    font-size: 12px;
  }
}

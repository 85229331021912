.form.u_submit {
  div.value {
    line-height: 34px;
  }

  .btn.dropdown-toggle.selectpicker.btn-default {
    .metric-plugin;
  }

  .panel-default .panel-heading .colapsing-selector {
    top: -6px;
  }
}
.annotatedListContainer {

  .annotatedListFilter {

    .inputContainer > input {
      line-height: 32px;
      border-radius: @inputBorderRadius;
      padding: 0 10px;
    }
  }

  .annotatedList {
    border-radius: @inputBorderRadius;

  }

  .annotatedList.inset {
    margin-top: 33px;
    min-height: 210px!important;
  }
}

.conversationAnnotatedList {
  display: inline-block;

  .selectedConversations {
    width: 478px !important;
  }

  .annotatedListContainer:not(.selectedConversations) .annotatedList {
    margin-top: 40px !important;
  }

  .annotatedListGridConversations  {
    margin-top: 40px !important;

    .ag-body-viewport {
      border-radius: 3px;
    }
  }

  .annotatedListGrid {
    .ag-body-viewport.ag-layout-normal {
      border-radius: 3px;
    }

  }
}

.annotatedListGrid .annotatedGridSelector {
  border-radius: 3px;

  &.selector.single {
    width: 100%!important;

    .ag-center-cols-container {
      width: 100%!important;

      .ag-cell {
        width: 100%!important;

        .ag-group-value {
          line-height: 23px;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}

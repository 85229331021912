// Inputs
input[type=color],
input[type=date],
input[type=datetime-local],
input[type=datetime],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
input[type=week],
textarea,
textarea.form-control {
  padding: 5px 8px;
  border: 1px solid @input-border-color;
  border-radius: @inputBorderRadius!important;
}

input[type=submit] {
  background-color: @submit-background-color;
  border-radius: 9999px!important;
}

//Checkbox
div.httpool-checkbox-button {
  background-image: url(../../images/new/tw-unchecked.png);
}

div.httpool-checkbox-checked {
  background-image: url(../../images/new/tw-checked.png);
}

div.httpool-checkbox-disabled {
  background-image: url(../../images/new/tw-check-disabled.png);
}
div.httpool-checkbox-checked-disabled {
  background-image: url(../../images/new/tw-check-selected-disabled.png);
}

//Radiobutton
div.httpool-radio-button {
  background-size: 12px 12px;
  background-image: url(../../images/new/tw-radio-unselected.png);
  display: inline;
}

div.httpool-radio-checked {
  background-size: 12px 12px;
  background-image: url(../../images/new/tw-radio-selected.png);
}


div.httpool-radio-checked-disabled {
  background-size: 12px 12px;
  background-image: url(../../images/new/tw-radio-selected-uneditable.png);
  cursor: not-allowed;
}

div.httpool-radio-unchecked-disabled {
  background-size: 12px 12px;
  background-image: url(../../images/new/tw-radio-unselectable.png);
  cursor: not-allowed;
}

.form input.value.full-width {
  width: 100%;
}

.form input.value.long,
.form input.value,
input.datetimepicker-datepicker.value,
.form input.datetimepicker-timepicker.value,
#daily_budget
{
  line-height: 24px;
  border-radius: @inputBorderRadius;

  &.parent-entity-search {
    width: 100%!important;
    margin-bottom: 10px;
    padding-left: 15px!important;
  }
}

.metric-plugin {
  line-height: 24px;
  border-radius: @inputBorderRadius;
  border-color: @input-border-color;
  background-color: white;
  border-width: 1px;
  height: 34px;
  color: @fontColor;
}

#Metric {
  .metric-plugin;

  &.dropdown-toggle {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &:hover, &:focus, &:active {
    background: transparent;
    color: @fontColor;
  }
}

.bulk-edit-bid .react-select-wrapper{
  width: 220px;
  display: inline-block;

  .react-select__dropdown-indicator {
    padding: 6px;
  }

  .react-select {
    margin-right: 0;
  }
}

  //Labels
.form label.caption {
  line-height: 32px;
  font-weight: 700;
}

.form label .help-block {
  margin-top: -20px;
}

.remove-input, .add-input {
  margin-top: 3px;
}

a.Select-value-label {
  .active {
    color: white !important;
  }
}

form.form.form-login {
  padding-bottom:30px;
}

.Select.appSelector {
  .Select-value-label {
    padding: 6px 5px;

    i {
      margin-right: 5px;
      margin-left: 5px;
    }
  }

  .Select-value-icon {
    padding: 4px 5px 0;
  }
}

.addAppButton {
  background: #1997f0;
  border-radius:  3px;
  width: 34px;
  line-height: 34px;

  &:hover {
    background: #0662cf;
  }
}

.appSelectOption {
  i {
    background-color: #888888;
    border-radius: 5px;
    color: white;
  }
}

.video-control-full-width .progress {
  border-radius: 22px;
}

#tweet-body {
  height: 125px;
  resize: none;
  width: calc(100% - 40px)!important;
}

#tweetBodyCounter {
    left: 320px;
}

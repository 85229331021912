.daterangepicker {
    td.active,
    td.active:hover,
    .ranges li.active {
        background: #1997f0;
    }
}

.startTimeDatePicker, .endTimeDatePicker {
    position: relative;
}

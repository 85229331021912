.twitter-group-page .tabbable > ul.nav-tabs {
  > li > a:before{
    display:inline;
    font-family: 'Font Awesome 5 Free';
    margin-right:10px;
  }
  > li:nth-child(1) > a:before {
    content: '\f03a';
  }

  > li:nth-child(2) > a:before{
    content: '\f1d8'; /*'\f080';*/
  }

  /*>li:nth-child(3) > a:before{
    content: '\f1d8';
  }*/
}

.container > ul.twitter-campaign-list.nav-tabs, .twitter-clientstats-page .tabbable > ul.nav-tabs  {
  > li > a:before{
    display:inline;
    font-family: 'Font Awesome 5 Free';
    margin-right:10px;
  }

  > li:nth-child(1) > a:before {
    content: '\f03a';
  }

  > li:nth-child(2) > a:before{
    content: '\f080';
  }

  >li:nth-child(3) > a:before{
    content: '\f085'; /*'\f1d8';*/
  }

  >li:nth-child(4) > a:before{
    content: '\f108';
  }
}

.adAccount-list > .tabbable > ul.nav-tabs, .twitter-stats-screen .tabbable > ul.nav-tabs,
.details-screen > .tabbable > ul.nav-tabs, .twitter-stats-screen .tabbable > ul.nav-tabs {
  > li > a:before{
    display:inline;
    font-family: 'Font Awesome 5 Free';
    margin-right:10px;
  }

  > li:nth-child(1) > a:before {
    content: '\f03a';
  }

  > li:nth-child(2) > a:before{
    content: '\f002';
  }

  >li:nth-child(3) > a:before{
    content: '\f080';
  }
}

.twitter-ad-page .tabbable > ul.nav-tabs {
  > li > a:before {
    display: inline;
    font-family: 'Font Awesome 5 Free';
    margin-right: 10px;
  }


  > li:nth-child(1) > a:before {
    content: '\f03a';
  }

  > li:nth-child(2) > a:before{
    content: '\f1d8'; /*'\f080';*/
  }

  /*> li:nth-child(3) > a:before{
    content: '\f1d8';
  }*/
}

.dashboard-page-tw .tabbable > ul.nav-tabs {
  > li > a:before {
    display: inline;
    font-family: 'Font Awesome 5 Free';
    margin-right: 10px;
  }


  > li:nth-child(1) > a:before {
    content: '\f03a';
  }

  > li:nth-child(2) > a:before{
    content: '\f080';
  }

  > li:nth-child(3) > a:before{
    content: '\f085'; /*'\f1d8';*/
  }

  > li:nth-child(4) > a:before{
    content: '\f108';
  }
}

.rules .tabbable > ul.nav-tabs {
  > li > a:before{
    display:inline;
    font-family: 'Font Awesome 5 Free';
    margin-right:10px;
  }
  > li:nth-child(1) > a:before {
    content: '\f03a';
  }

  > li:nth-child(2) > a:before{
    content: '\f1d8'; /*'\f080';*/
  }

  /*>li:nth-child(3) > a:before{
    content: '\f1d8';
  }*/
}

.floater-estimates.estimates {
  border: 1px solid @input-border-color!important;
  border-radius: @borderRadius;

  .floater-spacer {
    height: 0px;
    border: none;
  }

  .floater-info {
    width: auto;
    float: left;
    border: none;
    margin: 10px;

    &.grayed-out {
      width: auto;
      float: left;
      border: none;
    }

    & tr td {
      display: block;
      &:first-child {
        padding: 0;
      }

      &:last-child {
        padding: 0;
        text-align: left;
      }
    }
  }

  &>.floater-content-box {
    padding: 0;
    border: none;

    &> div > .daily-estimates {
      clear: both;
      overflow: hidden;
      border-top: 1px solid @input-border-color;
      border-bottom: 1px solid @input-border-color;
      background-color: @highlight-background-color;

      .twProgress.audience-progress.progress {
        border-radius: 9999px;
        height: 10px;
        margin: 0 10px;

        .progress-bar {
          border-radius: 9999px;
        }
      }
    }
  }

  &> div:nth-child(1).floater-content-box {
    border-top-left-radius: @borderRadius;
    border-top-right-radius: @borderRadius;
  }

  &> div:nth-child(3).floater-content-box {
    border-top: none;
    background:white;

    .floater-titled-row {
      background:white;
      border: none;
    }
  }

  .button_container {
    margin-top: 20px;
    .cancel-button {
      border-width: 1px;
      background: #fff;
      &:hover {
        background-color: #ecf5fd;
      }
    }
  }

  .cancel-link,
  .cancel-button,
  .submit {
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 10px;
    width: 90%;
  }
}

.sidemenu-footer {
  background: #596c7b;

  .sidemenu-info {
    color: #707070;

    .sidemenu-email a {
      color: white;
      text-decoration: underline;

      &:hover {
        color: #eee;
      }
    }
  }
}

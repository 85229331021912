.twitter-group-page {
  @media (max-width:766px) {
    .twPageHeader.page-header.dashboard-header {
      margin-top:50px;
    }
  }

  .twPageHeader.page-header.dashboard-header {
    position: relative;

    .campaign-name {
      width: 55%;
      display: inline-block;
      padding-top: 20px;
      padding-bottom: 20px;
      float: left;
      overflow:hidden;
      text-overflow: ellipsis;
    }

    .menu-action {
      li {
        margin-bottom:20px;
      }

      float: right;
      padding-top: 20px;
      padding-bottom: 20px;
      width: auto;
      max-width: 45%;
      bottom: auto;
      position:initial;
    }

    .menu-action:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0;
    }


    > h1 {
      width: 100%;
    }

    >h1:after {
      content: " ";
      display: block;
      clear: both;
    }
  }
}

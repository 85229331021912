.panel:before {
  border: none;
}

.react-panel-content-margin {
  margin: 0;
}

.panel-heading {
  padding-left: 0 !important;
}

.panel-light {
  .panel-heading-title {
    position: relative;
    display: inline-block;
  }

  .colapsing-selector {
    left: auto;
    right: -21px;
    top: 4px;
    font-size: 12px;
  }

  .panel-body {
    margin-top: 0;
    border: none;
  }
}

.panel {
  .colapsing-selector {
    top: 1px
  }
}

.campaign-list {
  .panel-default .panel-heading {
    .colapsing-selector {
      left: 4px;
    }
  }
}

.js-panel-bs-collapse {
  .panel-heading {
    display: inline;
  }
  .colapsing-selector {
    left: auto;
    right: -21px;
    top: -1px;
    font-size: 12px;
  }
}

.details-screen .panel-body .ag-bootstrap {
  margin-left: 0;
  margin-right: 0;
}

.details-screen .panel-body div:not(.no-margin-left)>.alert {
  margin-left:0;
}

.panel {
  margin-left: 20px;
}

.panel-light .panel-body {
  border: none;
}

.panel-default .panel-heading{
  padding-left:0 !important;

  .panel-title {
    font-weight: bold;
    color: #122A34;
    font-size: 14px;
    text-transform: uppercase;
    vertical-align: text-bottom;
  }

  .colapsing-selector {
    color: #122A34;
    font-size: 18px;
    left: -27px;
    top: 1px;
  }
}

.panel-flat.panel {
  border-bottom: none;
}

.rs-picker-daterange-menu, .rs-picker-popup-daterange, .overlap {
  z-index: 1000 !important;
}

.pacing-schedule-daterange, .rs-picker-daterange-calendar-group {
  min-width: 528px;
}

.loadingx:before {
  position: absolute;
  content: '';
  top: 0%;
  left: 0%;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  border-radius: 0.28571429rem;
  z-index: 100;
}

.loadingx:after {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -1.5em 0em 0em -1.5em;
  width: 3em;
  height: 3em;
  -webkit-animation: segment-spin 0.6s linear;
  animation: segment-spin 0.6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: #223088 rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
  border-style: solid;
  border-width: 0.2em;
  box-shadow: 0px 0px 0px 1px transparent;
  visibility: visible;
  z-index: 101;
}

@-webkit-keyframes segment-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes segment-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#ab-select button {
  padding-right: 30px !important;
}

.ab-dropdown-ui button {
  width: auto !important;
  border-radius: 3px;
  box-shadow: none;
  border-bottom: 1px solid #d5d5d5;
  display: block;
  padding: 0 0 0 8px;
  overflow: hidden;
  position: relative;
  border: 1px solid #d3d3d3;
  white-space: nowrap;
  line-height: 27px;
  text-decoration: none;
  background-clip: padding-box;
  background-color: #fff;
  transition: all .3s ease 0s!important;
  color: #858585;
  background-color: #fff;
  border-color: #d5d5d5;

  background-color: transparent;
  border-radius: 3px;
  box-shadow: none;
  border-bottom: 1px solid #d5d5d5;

  height: 34px !important;
  line-height: 32px;
  background-color: transparent;
  padding-right: 30px !important;


  font-size: 14px;
  font-weight:400;

}

.ab-dropdown-ui button > span{
  line-height: 20px;
  font-size: 14px;

}

.ab-active-ads .col-xs-1:nth-child(1) {
  width: 160px !important;
}
.ab-active-ads .col-xs-1:nth-child(2) {
  width: 100px;
}
.ab-active-ads .col-xs-1:nth-child(3) {
  width: 150px;
}
.ab-active-ads .col-xs-1:nth-child(4) {
  width: 124px;
  padding-left: 20px;
}
.ab-active-ads .col-xs-1:nth-child(5) {
  width: 150px;
}
.ab-active-ads .col-xs-1:nth-child(6) {
  width: 160px;
}

input.no-focus {
  font-weight: normal;
}

input.no-focus:focus {
  outline: none !important;
  border:1px solid rgb(181, 181, 181);
  box-shadow: none;
}

#ab-wrapper {
  margin-bottom: 0px;
}

#ab-wrapper .fb-rule-list-holder.single-rule-container{
  margin-bottom:40px
}

#ab-wrapper-button {
  margin-bottom: 0;
}

.rule-title.single-rule-info-container {
  padding: 0 0 0 5px;
}

.ab-switch {
  &.btn-group {
    button.btn.btn-default {
      color: #707070;
      background-color: #fff;
      border-radius: @borderRadius @borderRadius 0 0 !important;
      border: none;
      padding: 12px 12px 8px 14px;
      -webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important;

      &:hover {
        color: #4d4d4d;
      }

      &.active{
        background-color: #e9ecef;
        color: @mirage;
        border-radius: @borderRadius @borderRadius 0 0 !important;

      }
    }
  }
}


.multi-select .text-core .text-wrap {
  input {
    font: 11px "Roboto", sans-serif;
  }

  .text-dropdown {
    font: 11px "Roboto", sans-serif;
  }

  .text-tags {
    .text-tag {
      .text-button {
        font: 11px "Roboto", sans-serif;
      }
    }
  }
}

.form div.subtitle {
  font-weight: 400;
}

#addCtaGroupButton {
  height: 79px !important;
  z-index: @zindex-modal;
}

#removeCtaGroupButton {
  height: 79px !important;
  margin-left: 555px !important;
  z-index: @zindex-modal;
}

.number-counter {
  position: relative;
  top: 25px;
  right: 25px;
  font-size: 9px !important;
  color: #a9a9a9 !important;
}

.welcomeMessageEditIconSelected {
  color: white !important;
}

.welcomeMessageEditIconSelected:hover {
  color: #666666 !important;
}

.welcomeMessageCopyIconSelected {
  color: white !important;
}

.welcomeMessageCopyIconSelected:hover {
  color: #666666 !important;
}

.welcomeMessageDeleteIconSelected {
  color: white !important;
}

.welcomeMessageDeleteIconSelected:hover {
  color: #E54028 !important;
}

.tweetBodyTextModal {
  .modal-body {
    padding-bottom: 0;
  }
}

.tweetBodyTextModalPreview {
  width: 520px;

  .modal-body {
    padding-bottom: 0;
  }

  .textAreaTweetPreview {
    cursor: not-allowed;
    height: 100px;
    width: 100%;
    resize: none;
  }
}

.tapPlacementBullets {
  margin-left: 40px;

  &>div {
    color: #666;
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
  }
}

// RIGHT MENU SIDEBAR
.cbp-spmenu-right {
  background: @highlight-background-color;

  .navbar-inverted {
    background: white;
    padding-top: 50px;
    padding-bottom: 0;
    border-bottom: 1px solid @input-border-color;

    .navbar-submenu {
      .notification-title {
        font-size: 13px;
        background: white;
        color: @nav-bar-background-color;
      }

      &:after {
        top: -30px;
        bottom: 0;
        font-size: 22px;
        width: 22px;
        height: 22px;
        color: @nav-bar-background-color;
        opacity: 1;
        box-shadow: none;
        border-bottom: none;
      }
    }
  }

  .navbar-nav.nav {
    float: none;

    .notification-list.is-navbar {
      li {
        border-bottom: 1px solid @input-border-color;

        span {
          color: @nav-bar-background-color;
        }

        a {
          color: black;
        }
      }
    }
  }

  .view-all.is-navbar {
    bottom: unset;
    left: 20px;
    right: 20px;
    padding: 0;

    & > a {
      color: @submit-background-color;
      text-decoration: none;
    }
  }

}

.change-log-page-holder .content-bg-grey{
  padding: 0 !important;
}

.reminder-grid {
  padding: 0 30px 0 20px;

  .ag-cell-centered {
    text-align: left;
    padding: 0 0 0 10px;
    display: flex;
    align-items: center;
  }

  .ag-row {
    color: #707070;
    font-family: 'Roboto', sans-serif;
  }

  .ag-header-row {
    color: #707070;
    font-family: 'Roboto', sans-serif;
    background-color: #e9ecef !important;
    white-space: normal;
  }

  .ag-cell:focus{
    border:none !important;
    outline: none;
  }

  .ag-row .ag-header-row .ag-header-cell {
    background-color: #f4f7f9;
    font-weight: normal;
    padding-top: 3px !important;
  }

  .ag-header-row .ag-header-cell {
    padding: 8px 0 8px 0;
  }

  .ag-header-row .ag-header-cell:nth-child(1) {
    left: 75px !important;
  }

  .ag-header-row .ag-header-cell:nth-child(n+2) {
    padding-left: 10px !important;
  }

  .ag-row .ag-header-row .ag-header-cell:nth-child(1) {
    padding-left: 75px !important;
  }

  .ag-header-icon{
    visibility: hidden !important;
  }

  .ag-header-cell-text {
    font-weight: bold;
  }

  .ag-center-cols-container {
    .ag-row:nth-child(odd) {
      background-color: white !important;
    }
    .ag-row:nth-child(even) {
      background-color: #f4f7f9 !important;
    }
  }

  .ag-group-expanded{
    padding: 0 30px 0 30px;
    cursor: pointer;
    font-size: 18px;
  }

  .ag-group-contracted{
    padding: 0 34.5px 0 30px;
    cursor: pointer;
    font-size: 18px;
  }
}

// Changelog
.changelog-list-ag-grid{
  .ag-react-container {
    width: inherit;
  }

  .ag-cell-centered {
    text-align: left;
    padding: 0px 0px 0px 10px;
    display: flex;
    align-items: center;
  }

  .ag-row {
    color: #707070;
    font-family: 'Roboto', sans-serif;
  }

  .ag-header-container{
    width: 1015px !important;
  }

  .ag-header-row {
    color: #707070;
    font-family: 'Roboto', sans-serif;
    background-color: #e9ecef !important;
  }

  .ag-cell:focus{
    border:none !important;
    outline: none;
  }

  .ag-row .ag-header-row .ag-header-cell {
    background-color: #f4f7f9;
    font-weight: normal;
    padding-top: 3px !important;
  }

  .ag-header-row .ag-header-cell {
    padding: 8px 0px 8px 0px;
  }

  .ag-header-row .ag-header-cell:nth-child(1) {
    padding-left: 15px !important;
  }

  .ag-header-row .ag-header-cell:nth-child(n+2) {
    padding-left: 10px !important;
  }

  .ag-row .ag-header-row .ag-header-cell:nth-child(1) {
    padding-left: 75px !important;
  }

  .ag-header-icon{
    visibility: hidden !important;
  }

  .ag-row-odd .ag-cell-not-inline-editing {
    background-color: white !important;
  }

  .ag-group-expanded{
    padding: 0px 30px 0px 30px;
    cursor: pointer;
    font-size: 18px;
  }

  .ag-group-contracted{
    padding: 0px 34.5px 0px 30px;
    cursor: pointer;
    font-size: 18px;
  }

  .ag-details-row {
    display: inline-block;
  }

  .ag-details-row .ag-body-viewport{
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .settings-cell {
    padding-left: 75px;
  }

  .ag-loading-icon {
    visibility: hidden !important;
  }

  .ag-loading-text {
    visibility: hidden !important;
  }
}

.action-overlay .changelog-list {
  background-color: #fff;
  color: @mirage;

  .filters {
    padding-bottom: 40px;
  }

  .react-select__indicator.react-select__dropdown-indicator {
    padding: 6px;
  }

  .react-select__control {
    height: 34px;
  }

  .dropdown-menu {
    min-width: 110px !important;
  }
}

.changelog-list {
  .action-overlay-changelog {

    .load-more {
      color: initial;
    }

    .changelog-list-container > .table > thead > tr > th {
      background-color: #e9ecef;
    }

    .table {
      thead tr th {
        color: @fontColor;
      }

      tbody {
        border-bottom: none;

        &.has-diff {
          &:nth-child(even) {
            background: white;

            thead > tr > th:first-child {
              background: transparent;

              &:hover {
                background: transparent;
              }
            }

            tbody > tr > td:first-child {
              background: transparent;
              border-bottom: none;

              &:hover {
                background: transparent;
              }
            }
          }

          &:nth-child(odd) {
            background: #f4f7f9;

            &:hover {
              background-color: rgba(0, 0, 0, .05);
            }

            thead > tr > th:first-child {
              background: transparent;

              &:hover {
                background: transparent;
              }
            }

            tbody > tr > td:first-child {
              background: transparent;
              border-bottom: none;

              &:hover {
                background: transparent;
              }
            }
          }

          &:hover {
            background: transparent;
          }

          a {
            color: @mirage;
          }
        }

      }
    }

    .navbar-nav.changelog-list-container {
    }

    .filters {
      .Select-control {
        background-color: #fff;
      }

      .Select-arrow {
        border-color: #5a6c7b transparent transparent;
      }

      .Select-value span {
        color: #5a6c7b !important;
        font-weight: bold;
      }
    }
  }
}

.changelog-list.cbp-spmenu-right.cbp-spmenu-open {
  min-width: 745px;
  width: 50%;
  bottom: 0;
  top: unset;

  .sidebar-main-container {
    margin-bottom: 20px;
    height: 439px;
  }

  .nav.navbar-nav.navbar-inverted {
    padding-top: 30px;
    padding-left: 30px;
    padding-bottom: 6px;
    background: @fontColor;

    & > .navbar-submenu {

      .notification-title {
        background: @fontColor;
        color: white;
        font-size: 18px;
        font-weight: 700;
      }

      &:after {
        content: none;
      }

      button.selected-date-range-btn {
        background-color: white;
        color: @fontColor !important;
        .metric-plugin;
      }
    }
  }
}

.changelog-list.cbp-spmenu .filters .Select-value span {
  font-weight: normal;
}

.account-history-tooltip {
  white-space: pre;

  .tooltip-inner {
    max-width: none;
  }
}

.changelog-details-cell {
  padding-right: 5px;
  overflow:      hidden;
  text-overflow: ellipsis;
  white-space:   nowrap;
}

.import-summary-span {
  color:   #707070;
  display: inline-block;
}

.import-summary-icon {
  font-size: 12px;
}

.import-summary-text {
  font-size: 10px;
}

.import-summary-show-more-button {
  cursor: pointer;
}

.import-summary-table {
  height: 85%;

  .table {
    height: 100%;
  }

  th:nth-child(1), td:nth-child(1) {
    width: 45%;
  }

  th:nth-child(2), td:nth-child(2) {
    width: 15%;
  }

  th:nth-child(3), td:nth-child(3) {
    width: 39%;
  }

  td:nth-child(4) {
    width: 54px !important;
  }

  th:nth-child(4) {
    width: 54px !important;
  }

  tbody {
    overflow:auto;
    display:block;
    height: 100%;

    tr {
      width: 100%;
    }

    tr:nth-child(1) td {
      border-top: none;
    }
  }

  thead, tbody tr {
    display:table;
    table-layout:fixed;
  }

  thead {
    width: 100%;
  }
}

.bordered-row {
  border-top: rgba(255, 255, 255) 12px solid;
}

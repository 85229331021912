@import '../twitterAds/variables';

.header-filter {
  .filter-container {
    padding: 0 0 35px 0;
    margin-bottom: 0;

    .form-group {
      margin-bottom: 0;
      .block {
        margin-bottom: 4px;
        margin-top: -21px;
      }
    }
  }

  .filter-container-wrapper {
    display: table;
    height: 100%;
    width: 100%;
  }

  .user-entity-search {

    .form-control:focus {
      border-color: rgba(82, 168, 236, 0.8);
      box-shadow: 0 0 8px rgba(82, 168, 236, 0.6);
      outline: 0 none;
    }
  }

  .selected-date-range-btn.btn.btn-default, #reportrange {
    color: #858585 !important;
    font-weight: 400;
    box-shadow: none;
    outline: none;
    outline-offset: initial;
    border-radius: @inputBorderRadius;
    border-color: #d5d5d5;
    border-width: 1px;

    &:focus {
      background-color: transparent;
    }

    &:hover {
      background-color: transparent;
      color: #858585;
    }

    span:nth-child(2) {
      padding-left: 10px !important;
    }

    > div {
      white-space: nowrap;
    }
  }

  .valueFilter .Select-input>input,
  .Select-input>input {
    padding-top: 4px;
  }

}

#filter-manager-button {
  background: transparent;
  color: @submit-background-color;
  border: 1px solid @submit-background-color;
  border-radius: 3px;
  .fa-save {
    color: @submit-background-color!important;
  }
}

.modal-dialog {
  .Selected-filter-div {
    background-color: #69b9f1;
    .Selected-filter-label {
      color: #fff;
    }

    .Select-value-icon {
      color: #fff;
    }
  }
}

.header-filter-flat {
  .filter-container {
    padding: 12px 20px;
    padding-left: 5px;
  }
  .filter-display-container > .row > [class*="col-"]:first-child {
    max-width: 60px;
  }
}

.dashboard-page-tw .header-filter-flat .filter-container {
  padding-left: 20px !important;
}

.header-filter-no-margin {
  margin: 0 -24px;
}

.header-filter-modal-opened {
  margin: 0 -38px 0 -24px;
}

.header-filter-flat {
  background: #f4f7f9;
  border-bottom: 1px solid #e8ecef;
}

.header-filter-flat .btn-group .bootstrap-select-react,
.header-filter-flat .bootstrap-select-react {
  width: auto;
  padding-right: 23px;
  color: @dodgerBlue;
  font-size: 13px;
  font-weight: normal;
}

.header-filter-flat .filter-item {
  &> a {
    color: @dodgerBlue;
    font-size: 13px;
  }

  .btn-group > .btn:hover,
  .btn-group > .btn:active,
  .btn-group > .btn:focus{
    z-index: inherit;
  }

  .btn-group .btn:first-child,
  .btn-group .btn:last-child,
  .btn-group .btn-group > .btn:first-child:not(:last-child),
  .btn-group .btn-group > .btn:last-child:not(:first-child) {
    border-radius: 50px;
  }

  .btn-group .btn.btn-default {
    background: @dodgerBlue;
    color: white;

    &:hover {
      background: @scienceBlue;
    }
  }

  .btn-group > .btn:first-child:not(:last-child),
  .btn-group .btn-group > .btn:last-child:not(:first-child){
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .btn-group > .btn:last-child:not(:first-child),
  .btn-group .btn-group > .btn:first-child:not(:last-child){
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    margin-right: 1px;
  }
}


.header-filter-flat .filter-item > a:hover {
  text-decoration: none;
}

.filter-item {
  .grey-button {
    position: relative;
    top: -1px;
    background-color: @grayColor;
    border-radius: 6px;
    text-align: center;
    width: 20px;

    .button-text {
      color: white;
      font-size: 9px;
      font-weight: normal;
    }
  }

  .item-text {
    margin-left: 8px;
  }
}

#dropdown-menu-align-right {
  border-color: @dodgerBlue;
  border-left-color: white;
  padding-right: 11px;

  &:hover {
    background: @scienceBlue;
    border-color: @scienceBlue;
    border-left-color: white;
  }
}

.save-button-dropdown {
  border-radius: 0 50px 50px 0 !important;
  border-color: #1997f0;
  border-right-color: white;
}

.level-switcher {
  position: absolute;
  bottom: 0;
  left: 250px;

  &.btn-group {
    button.btn.btn-default {
      color: #fff;
      background-color: @mirage;
      border-radius: @borderRadius @borderRadius 0 0 !important;
      border: 2px solid @mirage;
      border-bottom: none;
      padding: 12px 12px 8px 14px;
      -webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important;
      &:hover {
        background-color: @mirage;
        color: #c7d3dc;
      }

      &.active{
        background-color: @header-background-color;
        color: @mirage;
        border-radius: @borderRadius @borderRadius 0 0 !important;

        .fa.fa-plus-circle {
          cursor: pointer;
          vertical-align: middle;
          color: #dddddd;
          margin-right:2px;
          margin-left:8px;
          font-size: 17px;
          position: relative;
          top: -1px;

          &:hover {
            color: @dodgerBlue;
          }
        }
      }



      &.no-add-btn {
        padding-right: 14px;
      }

      .fa.fa-plus-circle {
        cursor: pointer;
        vertical-align: middle;
        color: #4c5b68;
        margin-right:2px;
        margin-left:8px;
        font-size: 17px;
        position: relative;
        top: -1px;
      }
    }
  }
}

.Select--single > .Select-control {
  border-radius: @inputBorderRadius;
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }

  .Select-value {
    line-height: 32px;
  }
}

.Select-menu-outer {
  border-radius: @borderRadius;
  box-shadow: none;
  overflow: hidden;

  .Select-menu {
    padding: 8px 0;
    max-height: 182px;

    .Select-option {
      padding: 7px 23px;
      &.is-selected,
      &.is-selected.is-focused {
        background: @submit-background-color;
        color: white;
        .text,
        .text-muted {
          color: white;
        }
      }
      &.is-focused {
        background: @highlight-background-color;
        .text,
        .text-muted {
          color: #333;
        }
      }
    }
  }
}

.twitter-objective-selector .Select-menu-outer {
  max-height: unset;

  .Select-menu {
    max-height: unset;
  }
}

.link {
  &.Select .Select-control {
    background: transparent;
    border: 0;
    box-shadow: none;
    height: auto;
  }
  &.has-value.Select--single > .Select-control .Select-value .Select-value-label {
    color: @dodgerBlue;
  }
 & .Select-arrow,
 &.is-open > .Select-control .Select-arrow {
    border-color: @dodgerBlue transparent transparent;
  }

 &.is-open > .Select-control .Select-arrow {
    border-width: 4px 4px 2px;
  }

 & .Select-option {
    line-height: 1.2em;
  }

 & .Select-placeholder,
 &.Select--single > .Select-control .Select-value {
    padding-left: 0;
  }
}

.long.adjustedItem.countries {
  z-index: 1070;
  input {
    box-shadow: none;
  }

  .react-select__single-value {
    color: #858585 !important;
  }
}

.bid-rule-bulk-actions {
  height: 50px;
  margin-left: 30px;

  &>div {
    display: inline-block;
    float: left;
  }

  .dropdown-toggle.btn-primary {
    font-size: 12px;
  }
}

.bidRulesGrid {
  max-width: 640px;
  line-height: 1.42857143;

  .ag-header-row {
    color: #707070;
    background-color: #e9ecef !important;

    .ag-icon-checkbox-checked {
      background-image: url('../../images/new/tw-checked.png');
    }

    .ag-icon-checkbox-unchecked, .ag-icon-checkbox-indeterminate {
      background-image: url('../../images/new/tw-unchecked.png');
    }

    .ag-icon.ag-icon-checkbox-indeterminate {
      padding: 15px 16px 0 15px;
    }

    .ag-header-cell:first-child {
      background-color: #fff !important;
    }
  }

  .ag-header-row .ag-header-cell {
    padding: 2px 7px;

    &:first-child {
      padding: 4px 0;
    }
  }

  .ag-header-icon{
    visibility: hidden !important;
  }

  .ag-row {
    padding: 2px 5px;

    .ag-icon-checkbox-checked {
      background-image: url('../../images/new/tw-checked.png');
    }

    .ag-icon-checkbox-unchecked {
      background-image: url('../../images/new/tw-unchecked.png');
    }

    .ag-cell {
      padding-top: 25px;
    }

    &.no-data-row {
      color:#ccc;

      .ag-icon {
        display: none;
      }
      .ag-group-value {
        padding-left: 8px;
      }
    }

    &.row-paused {
      color:#ccc;
    }

    &.first-change-day-invalid div:nth-child(2) > div > div p:nth-child(1),
    &.first-change-hour-invalid div:nth-child(2) > div > div p:nth-child(2),
    &.first-change-action-invalid div:nth-child(2) > div > div p:nth-child(3),
    &.second-change-day-invalid div:nth-child(3) > div > div p:nth-child(1),
    &.second-change-hour-invalid div:nth-child(3) > div > div p:nth-child(2),
    &.second-change-action-invalid div:nth-child(3) > div > div p:nth-child(3)
    {
      color: red;
    }

    &.ag-row-level-0 {
      .ag-cell,
      .ag-stub-cell {
        padding-left: 9px;
      }
    }
  }
}

.bidRuleEditButton {
  color: @dodgerBlue;
  cursor: pointer;
}

.bidRuleDeleteButton {
  color: @brand-danger;
  cursor: pointer;
}

.bid-rule-form-row {
  margin-left: 55px;
}

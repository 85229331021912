.url-trackers-title-container {
  span {
    line-height: 34px;
    font-weight: 700;
    color: #666;
    font-size: 14px;
  }
}

.url-trackers-input-container {
  display: flex;

  .url-trackers-input {
    min-width: 50%;
    padding: 10px;
    resize: none;
    margin-right: 30px;

    &.readonly {
      cursor: not-allowed !important;
      background-color: #eee !important
    }
  }

  .url-trackers-input-card {
    width: 350px;
    padding: 10px;
    resize: none;
    margin-right: 5px;

    &.readonly {
      cursor: not-allowed !important;
    }
  }

  .url-trackers-input-card:hover {
    border-color: #d5d5d5 !important;
  }
}

.url-trackers-dropdown {
  left: 0;
  right: initial;
  width: 275px;
}

/*Font face mixin*/
.font-face(@short-name:sans-serif; @fontName: sans-serif; @fontWeight: normal; @fontStyle: normal) {
  @font-face {
    font-family: @short-name;
    src: url('@{font-path-twAds}@{fontName}.eot');
    src: url('@{font-path-twAds}@{fontName}.eot?#iefix') format('embedded-opentype'),
    url('@{font-path-twAds}@{fontName}.woff') format('woff'),
    url('@{font-path-twAds}@{fontName}.ttf') format('truetype'),
    url('@{font-path-twAds}@{fontName}.svg#@{fontName}') format('svg');
    font-weight: @fontWeight;
    font-style: @fontStyle;
    font-display: block;
  }
}

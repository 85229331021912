.alert {
  &.alert-warning, &.alert-danger, &.alert-info {
    border-radius: @borderRadius;

    &.new-entity-limit-alert {
      margin-bottom: 0;
      margin-top: 20px;
    }
  }

  &.alert-success {
    background-color: #ccf9d3;
    border-color: #d8ffc6;
    color: #818181;
    border-radius: 8px;
  }
}

.alert-list .alert {
  padding-left: 24px;
}

.creative-validation-errors {
  ul, li {
    list-style: unset !important;
  }

  ul {
    padding-left: 20px;
  }
}

.alert-no-padding-top {
  padding-top: 0 !important;
}

.alert-no-margin-bottom div {
  margin-bottom: 0 !important;
}

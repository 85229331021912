// Login
body.login {
  background-color: @background-color;

  .main-login.col-md-4.col-md-offset-4.col-sm-6.col-sm-offset-3 {
    width: 100%;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;

    .logo {
      padding: 2px 0;
      line-height: 42px;
      background: @nav-bar-background-color;
      margin-left: 0;

      img {
        width: 150px;
      }
    }
  }

  @media (min-width: 992px) {
    .col-md-offset-4.col-sm-offset-3 {
      margin-left: 0px;
    }

  }

  @media (min-width: 768px) {
    .col-sm-offset-3 {
      margin-left: 0;
    }
  }

  & .box-login {
    border: 1px solid @border-color;
    margin-left: auto;
    margin-right: auto;
    margin-top: 150px;
    position: relative;
    padding: 0;
    max-width: 460px;

    & > a {
      display: inline-block;
      margin: 0 30px 20px;
    }

    & > .form-group {
      margin: 0;

      h3 {
        .toggle_register {
          margin: 0 0 -30px 0;
          padding: 30px;
          background-color: @highlight-background-color;
          font-size: 14px;
        }
      }
    }

    .form-login {

      .terms-agree-box {
        margin: 0 30px;
      }

      #error {
        border-radius: @borderRadius;
        margin-left: 30px;
        margin-right: 30px;
      }
    }

    .form-actions {

      label[for=remember], label[for=remember_me] {
        margin-left: 15px;
        margin-top: 5px;
      }

      .pull-right {
        float: left !important;
      }

      input[type=checkbox] {
        margin-right: 5px;
        margin-left: 15px;
      }
    }

    .form {
      h3 {
        font-size: 18px;
        margin-top: 0;
        padding: 25px 30px;
        background-color: @highlight-background-color;
      }

      fieldset {
        margin: 0 30px;
        padding: 0;

        & > .form-group input[type=text], input[type=password] {
          @media (max-width: 992px) {
            width: 100%;
          }
        }

        #captcha {
          width: 100%;
        }
      }

      & > .form-group {
        margin: 0 30px 15px 30px;

        input {
          width: 66.666666%;
          @media (max-width: 992px) {
            width: 100%;
          }
        }
      }

      & > .clearfix {
        margin: 0 30px 30px;
      }

      & > p {
        padding: 30px;
        margin: 0;
      }
    }

    & > h3 {
      margin: 30px 30px 30px 30px;
      font-weight: 700;
      color: @title-color;
    }

    & .input-icon .fa {
      display: none;
    }

    @media (max-width: 992px) {
      max-width: 460px;
      margin-left: auto;
      margin-right: auto;
    }

    @media (max-width: 768px) {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

  }
}

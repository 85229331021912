.ag-theme-senna {
  padding: 0 20px 0 0;
  color: #707070;
  font-family: 'Roboto', sans-serif;

  .ag-react-container {
    width: inherit;
  }

  .ag-header {
    margin-bottom: 10px;
    background-color: #e9ecef;
    color: #192430;
  }

  .ag-cell {
    line-height: 1.2em;
    padding: 12px 20px;
    display: flex;
    align-items: center;
  }

  .ag-cell:focus {
    border: none !important;
    outline: none;
  }

  .ag-row,
  .ag-row-odd {
    border-bottom: white 8px solid;
    border-top: white 8px solid;
  }

  .ag-row-odd {
    background-color: #f4f7f9;
  }

  .ag-row .ag-header-row .ag-header-cell {
    font-weight: normal;
    padding-top: 3px !important;
  }

  .ag-header-row .ag-header-cell {
    padding: 10px 20px;
  }

  .ag-header-icon {
    visibility: hidden !important;
  }

  .ag-group-expanded {
    padding: 0 30px 0 30px;
    cursor: pointer;
    font-size: 18px;
  }

  .ag-group-contracted {
    padding: 0 34.5px 0 30px;
    cursor: pointer;
    font-size: 18px;
  }

  /*Context Menu*/
  .ag-menu {
    margin-left: -140px;
  }

  .ag-menu .ag-menu-option {
    min-width: 120px;
  }

  /* Master Grid*/
  [class^='ag-row-level-'],
  [class*=' ag-row-level-']{
    &.ag-row,
    &.ag-row-odd {
      border-bottom: 0;
      border-top: 0;
    }
  }

  .ag-details-grid {
    padding: 0 ;
    .ag-header {
      margin-bottom: 0;
      background-color: #eef3f6;
    }

    .ag-body-viewport {
      padding-top: 0;
      padding-bottom: 0;
    }
    .ag-row-odd {
      background-color: inherit;
    }
    .idented {
      padding-left: 92px;
    }
  }
}

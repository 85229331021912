.scrollable .fdt-cellFooter {
  background: white;
}

.dash-border-t2 .twGridContainer {
  .fdt-cellNotTransparent {
    background: white !important;
  }
  .fixedDataTableLayout {
    &_rowsContainer {

      .fixedDataTableCellLayout_main:nth-child(2) .fdt-cellHeader {
        border-right: 1px solid @border-color;
      }

      .fixedDataTableRowLayout {
        &_body {
          .fixedDataTableCellGroupLayout {
            &_cellGroup {

              .fixedDataTableCellLayout {
                &_main {
                  &:nth-child(4) {
                    border: none !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.twGridContainer .fixedDataTableLayout_rowsContainer .fixedDataTableRowLayout_main:not(.fixedDataTableLayout_header):not(.fixedDataTableLayout_footer) .fixedDataTableRowLayout_body .fixedDataTableCellGroupLayout_cellGroup .fixedDataTableCellLayout_main:nth-child(6) {
  border-bottom: 1px solid #e4e4e4;
  .fdt-cell {
    border-bottom: none;
  }
}

.condenseGrids .twGridContainer .fixedDataTableLayout_main {
  .fixedDataTableLayout_rowsContainer .fixedDataTableRowLayout_rowWrapper .fixedDataTableRowLayout_main {

    &:not(.fixedDataTableLayout_header):not(.fixedDataTableLayout_footer) {
      .fixedDataTableRowLayout_body .fixedDataTableCellGroupLayout_cellGroupWrapper:nth-child(2) {
        .fixedDataTableCellGroupLayout_cellGroup .fixedDataTableCellLayout_main {
          border: none!important;

          border-left: 1px solid @border-color !important;
          border-bottom: 1px solid #e4e4e4!important;
          .fdt-cell {
            border-bottom: none;
          }
        }
      }
    }

    &.fixedDataTableLayout_header, &.fixedDataTableLayout_footer {
      .fixedDataTableRowLayout_body .fixedDataTableCellGroupLayout_cellGroupWrapper:nth-child(2) {
        .fixedDataTableCellGroupLayout_cellGroup .fixedDataTableCellLayout_main {
          border: none!important;

          .fdt-cellHeader {
            border-left: 1px solid @border-color !important;
          }
        }
      }
    }

  }
}


.twGridContainer .fixedDataTableLayout_rowsContainer
.fixedDataTableRowLayout_main:not(.fixedDataTableLayout_header):not(.fixedDataTableLayout_footer)
.fixedDataTableRowLayout_body .fixedDataTableCellGroupLayout_cellGroup .fixedDataTableCellLayout_main:nth-child(7) {
  border-bottom: 1px solid #E4E4E4;
}

.twGridContainer .fixedDataTableLayout_rowsContainer
.fixedDataTableRowLayout_main:not(.fixedDataTableLayout_header):not(.fixedDataTableLayout_footer)
.fixedDataTableRowLayout_body .fixedDataTableCellGroupLayout_cellGroup .fixedDataTableCellLayout_main:nth-child(8) {
  border-bottom: 1px solid #E4E4E4;
}

.twGridContainer {

  .twColHelpCursor.colWithTooltip {
    border: none;
  }

  .twSortWrap.fdt-cellHeader span {
    border: none;
  }

  .public_fixedDataTableRow_main:hover .fdt-cell:not(.fdt-cellTransparent) {
    background-color: @aquaHaze;
  }

  /*
  .fixedDataTableRowLayout_rowWrapper:nth-child(even) .public_fixedDataTableRow_main .fixedDataTableCellLayout_main.public_fixedDataTableCell_main .fdt-cell:not(.fdt-cellTransparent){
    background-color: @catskillWhite;
  }
  */

  .fixedDataTableLayout_main
  .fixedDataTableCellGroupLayout_cellGroup:first-child
  .fixedDataTableCellLayout_main:first-child .fdt-firstCell,
  .twGridContainer .public_fixedDataTable_footer
  .fixedDataTableCellLayout_main:first-child .fdt-firstCell {
    background: white!important;
    z-index: 1040;
  }

  .fdt-cellFooter.fdt-firstCell:before {
    left: 14px;
  }

  .public_fixedDataTableRow_main:hover .fixedDataTableCellGroupLayout_cellGroupWrapper:first-child {
    .fixedDataTableCellGroupLayout_cellGroup:first-child .fixedDataTableCellLayout_main:first-child .fdt-cell {
      background-color: transparent;
    }
  }

  .fdt-cellNotTransparent {
    background: white !important;
  }

  .fixedDataTableLayout {
    &_rowsContainer {

      .fixedDataTableCellLayout_main:nth-child(2) .fdt-cellHeader {
        border-right: 1px solid @border-color;
      }

      .fixedDataTableRowLayout {
        &_body {
          .fixedDataTableCellGroupLayout {
            &_cellGroup {

              .fixedDataTableCellLayout {
                &_main {

                  &:first-child {
                    border: none !important;
                  }

                  &:nth-child(2) {
                    border: none !important;
                  }

                  &:nth-child(3) {
                    .fdt-cellHeader.fdt-cellTransparent {
                      border-bottom: 2px solid @border-color !important;
                    }
                    border-left: none !important;
                  }

                  &:last-child {
                    border: none !important;
                  }

                  border-right: 1px solid @border-color !important;
                }
              }

              .public_fixedDataTable_footer {

              }
            }
          }
        }

        .fixedDataTableLayout_header {
          .public_fixedDataTableCell_main {
            border-right: 1px solid @border-color !important;
            &:first-child {
              border: none !important;
            }
          }
        }
      }
    }
  }

  .fdt-cellHeader:not(.fdt-cellTransparent) {
    border-bottom: 2px solid @border-color;
    color: @fontColor;
    font-weight: normal;
  }

  .fdt-cell {
    border-right: none;

    &.fdt-cellTransparent {
      border: none;

      &:nth-child(2) {
        border-bottom: 2px solid @border-color !important;
      }
    }
  }

  .fdt-cellAction {
    padding: 0;
    margin: 0;
    background-color: white;

    .btn-a1 {
      background: transparent;
      color: @border-color;

      &:hover {
        color: @dodgerBlue;
      }
    }
  }

  .fdt-cellHeader:not(.fdt-cellTransparent) {
    padding-left: 12px;
    padding-right: 6px;
  }
  .fixedDataTableLayout_footer .fdt-cell,
  .fdt-cellFooter {
    background-color: @highlight-background-color !important;
    line-height: 40px;
    font-weight: bold;
    color: @fontColor;
  }

  .campaignNameContainer > a, .twLinkFocusColor.groupName {
    color: @dodgerBlue;
    font-weight: bold;

  }

  &.scrollable .public_fixedDataTable_footer .fixedDataTableCellGroupLayout_cellGroup > .public_fixedDataTableCell_main {
    border-bottom: none;
    border-left: none;
  }

  .public_fixedDataTable_footer, .fixedDataTableCellGroupLayout_cellGroup:first-child{
    .fixedDataTableCellLayout_main:first-child .fdt-firstCell {
      background:transparent !important;
    }
  }

  .fdt-cell .txt-label{
    color:#333;
    font-size:12px;
  }

  .public_fixedDataTableCell_cellContent{
    .txt-label {
      color:#333;
    }
    .txt-value{
      font-size:11px;
      color: #707070;
    }
  }

  .campaignNameContainer>a,
  .twLinkFocusColor.groupName {
    color: #1997f0;
    font-weight: normal;
  }

  .twCampaignListIcon {
    display: none;
  }

}

.twPopover,
.twPopover-content {
  color:white;
  background-color: #333;
}

.twPopover.right .arrow{
  border-right-color: #333 !important;
}

.twPopover.left .arrow{
  border-left-color: #333 !important;
}

.dropdown-a1.dropdown-a1-has-arrow.dropdown-menu>li:first-child:before{
  display:none;
}

.dropdown-a1{
  background: white;
  border-radius:7px;
  border: 1px solid @border-color;
  overflow: hidden;
}

.dropdown-a1.dropdown-menu li:hover a{
  background: @border-color !important;
}

.dropdown-a1.dropdown-menu li a {
  color: #333 !important;
}

.panel-title{
  text-transform: capitalize;
}

.twGridContainer{
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.dropdown-a1.dropdown-menu li:hover a,
.dropdown-a1.dropdown-menu a:focus,
.dropdown-a1.dropdown-menu a:hover {
  background: @highlight-background-color!important;;
}

.fdt-cellFooter.fdt-cellNotTransparent {
  background: white;
}

.fdt-cellFooter, .fdt-cellHeader:not(.fdt-cellTransparent), .fdt-nameHeader {
  background: white;
}

.twGridContainer.scrollable .public_fixedDataTable_footer .fixedDataTableCellGroupLayout_cellGroup>.public_fixedDataTableCell_main {
  border-right: none!important;
}
/*.condenseGrids .twGridContainer .fixedDataTableLayout_main .fixedDataTableLayout_rowsContainer .fixedDataTableRowLayout_rowWrapper .fixedDataTableRowLayout_main:not(.fixedDataTableLayout_footer) .fixedDataTableRowLayout_body .fixedDataTableCellGroupLayout_cellGroupWrapper:nth-child(2) .fixedDataTableCellGroupLayout_cellGroup .fixedDataTableCellLayout_main:nth-child(2),
.condenseGrids .twGridContainer .fixedDataTableLayout_main .fixedDataTableLayout_rowsContainer .fixedDataTableRowLayout_rowWrapper .fixedDataTableRowLayout_main:not(.fixedDataTableLayout_footer) .fixedDataTableRowLayout_body .fixedDataTableCellGroupLayout_cellGroupWrapper:nth-child(2) .fixedDataTableCellGroupLayout_cellGroup .fixedDataTableCellLayout_main:nth-child(3)
{
  border-bottom: 1px solid @border-color!important;
}*/

.twGridErrors.gridErrors {
  margin-top: 20px;
}
@import "../bootstrap/mixins/vendor-prefixes.less";
@import "../elements";
@import "../twitterAds/variables";

@import (less) "../plugins/introjs.css";

@background: #596c7b;
@backgroundHover: #4b5b68;
@borderColor: #697889;
@itemColor: #c7d3db;
@itemHoverColor: #fff;
@panelSlideAnimation: .2s 1 both;
@navBgColor: #192430;


/* HAMBURGER */
.left-menu-toggler {
  background: @navBgColor;
  left: 0;
  top: 0;
  padding-top: 7px;

  &.burger-button {
    color: #fff;
  }
}

.main-content {
  width: calc(100% - 50px);
  margin-left: 50px !important;
}

/*CBP MENU*/
.cbp-spmenu-vertical {
  top: 46px;
  width: 252px;
  overflow: hidden;
  white-space: nowrap;
}

.cbp-spmenu-left.cbp-spmenu-open {
  .box-shadow(none);
}

.isSwitched,
.isLoggedOut,
.isPlatformUpdated {
  .cbp-spmenu-vertical {
    top: 42px;
  }
  .cbp-spmenu .sidemenu-footer {
    bottom: 42px;
  }
}

.isSwitched.isLoggedOut,
.isSwitched.isPlatformUpdated {
  .cbp-spmenu-vertical {
    top: 84px;
  }
}

.cbp-spmenu {
  background: @background;
}

.cbp-spmenu .navbar-inverted {
  background: @backgroundHover;
}

.cbp-spmenu {

  // MENU ANIMATION
  &.cbp-spmenu-left {
    &.in-left-menu-enter {
      .animation(slideinleft @panelSlideAnimation);
    }
  }

  a {
    color: @itemColor;
  }

  a:hover {
    color: @itemHoverColor;
    text-decoration: none;
  }

  .navbar-nav.navbar-items {
    padding: 0;
  }

  .navbar-items:after,
  .navbar-nav.header:after {
    display: none;
  }

  .navbar-nav.header {
    .logo-menu {
      position: absolute;
      top: -46px;
      width: 100%;
      left: 0;
      background: @navBgColor;
      display: block;
      height: 46px;
      overflow: hidden;
      padding: 6px;
      > img {
        padding: 0;
        height: 100%;
        max-height: 34px;
      }
    }

    .close {
      top: -39px;
      right: 3px;
      margin: 4px 10px 0 0;
      color: @itemColor;
      opacity: 1;
      text-shadow: none;
      font-size: 28px;

      &:hover {
        color: @itemHoverColor;
      }
    }
  }

  ul.main-navigation-menu {
    > li {
      border-top: 1px solid @borderColor;
      border-collapse: collapse;

      &:last-child {
        border-bottom: 1px solid @borderColor;
      }

      &:hover {
        width: auto;
      }

      > a {
        padding: 20px 15px 18px;

        > i {
          position: absolute;
          left: 14px;
          margin: 0;
          min-width: 20px;
          display: block;
        }

        .item-text {
          margin-left: 35px;
          display: inline-block;
        }
      }

      > a:hover {
        text-decoration: none;
        color: #fff;
        background: @backgroundHover;
      }

      &.active {
        position: relative;
        background: @backgroundHover;
        border-left: 3px solid #fff;

        > a {
          background: inherit !important;
          padding-bottom:17px;
          i {
            left: 11px;
          }
        }

        > ul.sub-menu {
          > li > ul {
            background: @backgroundHover;
          }

          > li > ul li {
            padding-left: 47px;
          }
        }
      }

      &.active > ul.sub-menu > li > a,
      > ul.sub-menu li.active > a {
        color: @itemHoverColor !important;
      }


      &:first-child:hover {
        width: inherit;
        position: relative;
        > a:after {
          display: none;
        }
      }

      > ul.sub-menu {
          display: block !important;
          padding-bottom: 0 !important;
          position: relative;
          > li > a {
            display: none;
          }

          > li > ul {
            background: @background;
            padding-bottom: 10px;

            li {
              padding-left: 50px;
              padding-right: 15px;
            }
          }
      }

      > ul.sub-menu,
      &:hover > ul.sub-menu {
        left: inherit;
        width: auto;
        z-index: auto;
        margin-top: 0;
        margin-bottom: 0;
        top: inherit;
      }

    }
  }

  // User Menu
  ul.main-navigation-menu.user-menu {
    margin: 15px 0 0 0;

    > li {
      border: none;

      &:hover {
        background: transparent;
        width: inherit;

        > ul.sub-menu {
          width: inherit;
          position: relative;
          z-index: inherit;
          left: inherit;
          top: inherit;
        }

      }

      ul.sub-menu {
        margin-top: -44px;
        margin-left: 30px;

        margin-bottom: 0;
        padding-bottom: 0 !important;

        display: block !important;

        > li {
          &:first-child {
            padding: 12px 20px;
          }

          .tw-user-icon {
            display: none;
          }

          .username {
            font-size: 18px;
            font-weight: bold;
            line-height: 15px;
            color: #fff;
          }

          .fullname {
            color: @itemColor;
          }

          .username,
          .fullname {
            display: block;
          }


          > ul {
            margin: 10px 0 0 -10px;
            background: @backgroundHover;

            li {
              padding-left: 0;
            }

            li:before {
              content: "\2022";
              color: #fff;
              display: inline-block;
              width: 17px;
              font-size: 23px;
              line-height: 10px;
              top: 4px;
              position: relative;
            }

            .left-menu-settings,
            .left-menu-logout {
              padding: 0 !important;
              border: 0;
              font-size: 13px;
              display: inline-block;
              margin-top: 10px;

              &:hover {
                background: none !important;
              }
            }
          }
        }
      }
    }
  }

  .fa {
    font-size: 18px;
    color: @itemColor;
  }

  .avatar {
    font-size: 18px;
  }

  .navbar-nav {
    padding: 20px 0;
  }

  .navbar-nav.header {
    padding: 0 10px;
  }

  .grey-button {
    position: relative;
    top: -1px;
    background-color: #13242c;
    border-radius: 3px;
    text-align: center;
    width: 20px;
    margin-right: 10px;

    .button-text {
      color: white;
      font-size: 9px;
      font-weight: normal;
    }
  }

  .sidemenu-footer {
    bottom: 0;

    .sidemenu-separator {
      border-color: @borderColor;
    }

    .sidemenu-info {
      color: @itemColor;
      .item-text{
        display: none;
      }
    }

    .language-changer {
      padding-left: 25px;
      border-top: 1px solid @borderColor;
      padding-top: 15px;
      margin-top: 20px;

      &.menu-item-holder .list-item {
        top: 28px;
      }

      .sel_lng {
        color: @itemColor;
      }

      .Select-menu-outer {
        bottom: 40px;
        top: inherit;
      }
    }

    .tutorial {
      margin-top: 20px;
    }

    .menu-item-holder {
      padding-left: 30px;
      position: relative;

      .list-item {
        display: block;
        position: absolute;
        left: 0;
        top: 7px;
      }

      .item-text{
        display: none;
      }
    }

    // User Menu Footer
    .username {
      font-size: 18px;
      font-weight: bold;
      line-height: 15px;
      color: #fff;
    }

    .fullname {
      color: @itemColor;
    }

    .username,
    .fullname {
      display: block;
    }

    ul.user-menu-list-circle {
      margin: 10px 0 0 -10px;

      li {
        padding-left: 0;
      }

      li:before {
        content: "\2022";
        color: #fff;
        display: inline-block;
        width: 17px;
        font-size: 23px;
        line-height: 10px;
        top: 4px;
        position: relative;
      }

      .left-menu-settings,
      .left-menu-logout {
        padding: 0 !important;
        border: 0;
        font-size: 13px;
        display: inline-block;
        margin-top: 10px;

        &:hover {
          background: none !important;
        }
      }
    }

    .left-menu-settings .item-text{
      display: inline;
    }

  }
}


/* NAVIGATION SMALL (COMPACT MENU) */

.navigation-small {

  .left-menu-toggler {
    z-index: 1040;
  }

  &.isSwitched,
  &.isLoggedOut,
  &.isPlatformUpdated {
    .cbp-spmenu .sidemenu-footer {
      bottom: 42px;
    }
  }

  .cbp-spmenu {
    width: 50px;
    overflow: unset !important;
    white-space: unset !important;

    .navbar-inverted {
      background: @background;
    }

    .close,
    ul.main-navigation-menu > li > a .item-text,
    ul.main-navigation-menu > li.txt-label,
    ul.main-navigation-menu > li > ul.sub-menu .list-item,
    ul.main-navigation-menu.user-menu li > ul.sub-menu,
    .sidemenu-footer .sidemenu-separator,
    .sidemenu-footer .menu-item-holder .menu-item-over {
      display: none;
    }

    .sidemenu-footer {
      padding: 0;
      bottom: 0;

      .menu-item-holder {
        width: 50px;
        height: 46px;
        position: relative;
        padding: 15px;
        margin: 0;
        border-bottom: 1px solid @borderColor;
      }

      .menu-item-holder:hover {
        cursor: pointer;
        background: @backgroundHover;

        .list-item.fa {
          color: @itemHoverColor;
        }

        .menu-item-over {
          color: @itemHoverColor;
          display: block;
          position: absolute;
          left: 50px;
          bottom: -1px;
          width: 220px;
          padding: 4px 10px 5px 20px;
          background: @backgroundHover;
          border: 1px solid @borderColor;
          border-left: 0;
          min-height: 47px;
          border-top-right-radius: @borderRadius;
          border-bottom-right-radius: @borderRadius;

          .user-title{
            padding-top: 10px;
            display: block;
          }

          .user-menu-list-circle {
            margin-top: 0;
            padding-left: 20px;
            padding-bottom: 10px;
          }

        }
      }

      .tutorial:hover {
        .tutorial-title {
          padding: 12px 10px 12px 20px;
          min-height: 47px;
        }
      }

      .language-changer.menu-item-holder {
        border-top: 0;
        margin-top: 0;
        .list-item {
          top: inherit;
        }
      }

      .tutorial {
        a {
          padding: 0;
          text-decoration: none;
        }
      }

      .list-item {
        top: inherit;
        left: inherit;
        position: relative;
      }

    }

    ul.main-navigation-menu > li > ul.sub-menu {
      display: none !important;
    }

    &.cbp-spmenu-left.cbp-spmenu-open {
      .box-shadow(none);
    }

    .navbar-nav {
      text-align: center;

      &.header {
        padding: 0;
        .logo-menu {
          display: none;
        }
      }
    }

    ul.main-navigation-menu li {
      text-align: left;
    }

    ul.main-navigation-menu > li {
      border: 0;
      padding: 0;
      border-top: 1px solid @borderColor;
      position: relative;

      &:last-child {
        border-bottom: 1px solid @borderColor;
      }

      &:first-child:hover {
        width: 50px;
      }

      > a {
        display: block;
        padding: 20px 14px;
        line-height: 1em;
        > i {
          position: inherit;
          left: inherit;
        }
      }

      &:hover {
        background: @backgroundHover;

        .fa {
          color: @itemHoverColor;
        }
      }

      &.active {
        & >a {
          padding-left: 11px !important;
          padding-bottom: 19px;
        }

        border-left: 3px solid #fff;
      }
    }

    ul.main-navigation-menu.user-menu {
      margin: 0;

      > li {

        &:last-child {
          border-bottom: 0;
        }

        ul.sub-menu {
          margin-top: 0;
          margin-left: 0;
          display: none !important;

          > li > ul {
            margin-left: 0;
          }
        }
        .user-icon {
          cursor: pointer;
          text-align: center;
          padding: 20px 0;
        }
      }
    }

    ul.main-navigation-menu > li > ul.sub-menu > li {
      margin-top: -1px !important;
      background: @backgroundHover;
      overflow: hidden;

      &:first-child {
        border-top-right-radius: @borderRadius;
        border-bottom-right-radius: @borderRadius;
      }

      > a {
        display: block;
      }

      a {
        background: @backgroundHover !important;

        &:hover {
          text-decoration: none;
        }
      }
    }

    ul.main-navigation-menu > li:first-child:hover > a:after {
      display: block;
    }

    ul.main-navigation-menu > li:hover,
    ul.main-navigation-menu.user-menu > li:hover {
      > ul.sub-menu {
        width: 220px;
        position: absolute;
        z-index: 1000;
        left: 47px;
        margin-top: 0;
        top: 0;
        display: block !important;
        padding-bottom: 10px !important;
      }
    }

    ul.main-navigation-menu > li:hover {
      > ul.sub-menu {
        > li > a {
          padding: 19px 10px 19px 20px !important;
          border: 1px solid @borderColor;
          border-left: 0;
          color: @itemHoverColor !important;

          &:hover {
            color: @itemHoverColor !important;
          }
        }

        > li ul {
          padding: 0;
          margin-top: -1px;
          margin-bottom: 20px;

          li {
            padding-left: 0;
            padding-right: 0;
          }

          > li > a {
            padding: 5px 10px 5px 20px;

            &:hover {
              color: @itemHoverColor !important;
            }
          }
        }
      }
    }

    ul.main-navigation-menu > li.active:hover {
      > ul.sub-menu {
        > li > a {
          padding: 19px 10px 18px 20px !important;
        }
      }
    }
  }
}

.react-panel-content-margin {
  margin: 0;

  .selected-date-range-btn {
    font-weight: normal;
  }

  label.caption {
    line-height: 34px;
    padding: 0;
    width: 100%;
    float: none;
    text-transform: uppercase;
  }

  label.metrics-caption {
    line-height: 34px;
    padding: 0;
    text-transform: uppercase;
  }

  div#hide-zeros {
    margin-bottom: 15px;
  }

  div.value {
    margin-left: 0;
  }

  .form .value label.one-ad-per-image {
    padding-top: 15px;
  }

  .report-metrics .report-metrics-picker>div .categories-column>div.category-list ul>div {
    margin-left: 0;
  }
}

.has-estimates.form {
  max-width: 1200px;
}

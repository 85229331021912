.report-grid {
  .ag-bootstrap {
    .ag-header-cell {
      line-height: 10px;
    }
    .ag-cell-not-inline-editing {
      padding: 0 7px;
    }
    .ag-cell.ag-first-column {
      text-align: left;
    }
  }
}

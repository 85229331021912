.dropdown-menu {
  margin-top: 6px;
  border-radius: @borderRadius;
  box-shadow: none;
  padding: 8px 0;
  z-index: @zindex-dropdown + 1;

  .divider {
    margin: 3px 0;
  }

  &.open {
    margin-top: 6px;
    border-radius: @borderRadius;
    box-shadow: none;
  }

  .inner {
    border-radius: @borderRadius;
    margin: 8px 0;

    li {
      a:hover {
        background: @highlight-background-color;
        color: #333;
        small {
          color: #333 !important;
        }
      }

      &.selected {
        & > a {
          background-color: @submit-background-color;
          .text, .text-muted {
            color: white!important;
          }
        }
      }
    }
  }

  &> li > a {
    padding: 7px 23px;
    //word-break: break-word;
    //white-space: normal;

    & > .text {
      margin: 0;
    }

    &:hover {
      background: @highlight-background-color;
      color: #333;
    }
  }
}

.open>.dropdown-toggle.btn-default{
  background-color: white;
  color: #858585;
  &:hover {
    color: #858585;
    border-color: #858585;
  }
}

.btn.dropdown-toggle.selectpicker.btn-default {
  font-weight: 400;
  box-shadow: none;
  outline: 0;
  outline-offset: initial;
  border-radius: @inputBorderRadius;
  border-color: #d5d5d5;
  border-width: 1px;
  background-color: white;
  color: @fontColor;

  &:hover, &:focus, &:active {
    background-color: white;
    outline: none!important;
    outline-offset: initial;
  }
}

.list-entity-menu{
  background: none;
  color: #c5d0d8;
  border:none;

  a{
    color: #707070;
    background: none;
    font-size: 18px;
    padding: 0 0;
    line-height: 46px;
    width: 25px;

    i {
      padding: 17px 10px;
      color: #959595;
      &:hover, &:active, &:focus-within {
        color: #1997F0;
      }
      &.disabled {
        color: rgba(149, 149, 149, .3);
        cursor: not-allowed;
      }
    }
  }
}
